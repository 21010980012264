import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { selectAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
	textColor: "AWPrimary.dark",
	field: {
		backgroundColor: "transparent",
		textColor: "AWPrimary.dark",
		fontWeight: "light",
		outline: "1px solid",
		outlineColor: "AWPrimary.dark",
		py: [2],
		ps: [6],
		pe: [9],
		paddingInlineEnd: [10],
		textAlign: "center",
		alignItems: "center",
		borderRadius: "full",
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.dark",
			color: "AWFunctional.white",
			"& svg": {
				color: "AWFunctional.white",
				strokeColor: "AWFunctional.white",
			}
		},
	},
	icon: {
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.dark",
			color: "AWFunctional.white",
			"& svg": {
				color: "AWFunctional.white",
				strokeColor: "AWFunctional.white",
			}
		},
	},

});

const sorting = definePartsStyle({
	field: {
		cursor: "pointer",
		ps: 2,
		py: 2,
		textAlign: "start",
		borderRadius: "0.25rem",
		height: "full",
		backgroundColor: "AWFunctional.lighterGray",
		color: "AWPrimary.dark",
		border: "none",
		outline: "0px",
		outlineColor: "none",
		"&:hover, &:active": {
			backgroundColor: "AWFunctional.lighterGray",
			color: "AWPrimary.dark",
			"& svg": {
				color: "AWPrimary.dark",
				strokeColor: "AWPrimary.dark",
			}
		},
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "AWPrimary.dark"
		},
	},
	icon: {
		color: "AWPrimary.dark",
		right: 0,
		position: "relative",
		pe: 2,
	}
})

const filterList = definePartsStyle({
	width: "full",
	field: {
		cursor: "pointer",
		backgroundColor: "transparent",
		ps: 3,
		py: 3,
		borderRadius: 0,
		minWidth: "5rem",
		width: "full",
		height: "full",
		textAlign: "start",
		color: "inherit",
		borderBottom: "1px solid",
		borderColor: "inherit",
		outline: "0px",
		outlineColor: "none",
		fontFamily: "body",
		fontSize: "1.125rem",
		fontWeight: "600",
		lineHeight: "1.2",
		"&:hover, &:active": {
			backgroundColor: "transparent",
			color: "inherit",
			"& svg": {
				color: "inherit",
				strokeColor: "inherit",
			}
		},
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "inherit"
		},
		"&:focus:not(:focus-visible)": {
			outline: "none",
		},
	},
})

const filterListSmall = definePartsStyle({
	width: "full",
	field: {
		transition: "outline 2s ease-in-out",
		cursor: "pointer",
		px: 2,
		py: 3,
		borderRadius: 0,
		width: "full",
		height: "full",
		textAlign: "start",
		color: "AWPrimary.dark",
		borderBottom: "1px solid",
		borderColor: "AWPrimary.dark",
		outline: "0px",
		outlineColor: "none",
		"&:hover, &:active": {
			backgroundColor: "transparent",
			color: "AWPrimary.dark",
			"& svg": {
				color: "AWPrimary.dark",
				strokeColor: "AWPrimary.dark",
			}
		},
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "AWPrimary.dark"
		},
		icon: {
			color: "AWPrimary.dark",
			right: 0,
			position: "relative",
		}
	},
})

export const Select = defineMultiStyleConfig({ baseStyle, variants: { sorting, filterList, filterListSmall } })
