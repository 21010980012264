import { cubicBezier } from "framer-motion"

type AnimationOptionsProps = {
    ease?: string,
    duration?: number,
    delay?: number,
}
export const galleryCurve = cubicBezier(0.81, 0, 0.23, 1)
export const curve = "easeOut" //cubicBezier(.03,.7,.57,.93) //cubicBezier(0.81, 0, 0.23, 1)
export const counterCurve = cubicBezier(.03,.7,.57,.93)
export const animationOptions = ({ ease, duration, delay }: AnimationOptionsProps) => ({ ease: ease ?? curve, duration: duration ?? 0.3, delay: delay ?? 0.2 })