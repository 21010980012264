import { type ComponentStyleConfig } from "@chakra-ui/react";
import { pxToRem } from "../utils/mapPxToRem";

export const Icon = (rtl = false): ComponentStyleConfig => ({
	// The styles all Headings have in common
	baseStyle: {
		boxSize: pxToRem("16px"),
	},
	sizes: {
		sm: {
			boxSize: pxToRem("16px"),
		},
		smmax: {
			boxSize: pxToRem("20px"),
		},
		md: {
			boxSize: pxToRem("25px"),
		},
		lg: {
			boxSize: pxToRem("40px"),
		},
		xl: {
			boxSize: pxToRem("66px"),
		},
	},
});
