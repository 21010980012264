import type { ChakraTheme } from "@chakra-ui/react";

export const sizes: ChakraTheme["sizes"] = {
	// base 1 = '4px'
	contentWrapperWidthXL: '95.5rem',//max content width of 1464px
	modulePY__SM: "1.5rem",
	modulePY__MD: "2rem",
	modulePY__LG: "2.5rem",
	modulePY__XL: "3rem",
	modulePY__XXL: "4.5rem", //lots of different vertical spacings in design. Lets try to make a system
	pageMarginSM: "1rem",
	pageMarginLG: "2rem",
	headerHXS: "3.75rem",
	headerHLG: "4.1875rem", //67px
	headerHLGExpanded: "11.3125rem", //181px
	headerHLGExpandedTop: "7.15rem", // 144px
	headerExpandedLogoWidth: "18rem", // Altered from design or it can't fit.
	headerExpandedLogoWidthLG: "10.777rem",
	headerExpandedLogoHeightLG: "1.588rem",
	headerExpandedLogoWidthSM: "7.875rem",
	640: "40rem",
	866: "54.125rem",
	1164: "72.75rem",
};