import React from "react";
import { Box, type BoxProps, useToken } from "@chakra-ui/react";

const getStyles: (color: string) => BoxProps["sx"] = (color: string) => ({
	position: "relative",
	width: "10px",
	height: "10px",
	borderRadius: "5px",
	backgroundColor: color,
	color: color,
	animation: "dot-flashing 1s infinite linear alternate",
	animationDelay: "0.5s",

	"&:before, &:after": {
		content: "''",
		display: "inline-block",
		position: "absolute",
		top: 0,
	},
	"&:before": {
		left: "-15px",
		width: "10px",
		height: "10px",
		borderRadius: "5px",
		backgroundColor: color,
		color: color,
		animation: "dot-flashing 1s infinite alternate",
		animationDelay: "0s",
	},
	"&:after": {
		left: "15px",
		width: "10px",
		height: "10px",
		borderRadius: "5px",
		backgroundColor: color,
		color: color,
		animation: "dot-flashing 1s infinite alternate",
		animationDelay: "1s",
	},

	"@keyframes dot-flashing": {
		"0%": {
			backgroundColor: color,
		},
		"50%, 100%": {
			backgroundColor: `rgba(0, 127, 173, 0.2)`,
		}
	}
})

export const ButtonLoader = () => {
	const [dotColor] = useToken("colors", ["AWFunctional.darkGray"])

	return <Box sx={getStyles(dotColor)} />
};

