import React, { type FC } from 'react';
import { type LinkSize, type LinkVariant } from "../Theme/components/Link";
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react'; // eslint-disable-line no-restricted-imports
import { type LinkFieldValue, useSitecoreContext } from "~/foundation/Jss";
import type { LinkProps as SitecoreLinkProps } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import type { Optional } from "utility-types";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "../Components/Link";

export type LinkProps = Optional<SitecoreLinkProps, 'field'> & {
	variant?: LinkVariant;
	size?: LinkSize;
	bgColor?: string;
	color?: string;
	bgColorHover?: string;
	ariaLabel?: string;
	dataLayer?: () => GTMDataLayer;
}

/** React component that turns Sitecore link values that start with / into react-router route links */
const RoutableSitecoreLink: FC<LinkProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();
	const editable = props.editable ?? true;
	const value = props.field?.value as LinkFieldValue | undefined;
	const href = value?.href;
	const hasValidHref = props.field && props.field.value && href;
	const isEditing = editable && (props.field?.editableFirstPart || props.field?.editableLastPart);

	if (!hasValidHref && !sitecoreContext.pageEditing) {
		return <></>;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { variant, ariaLabel, color, ...sitecoreProps } = props;

	return (
		<>
			{!isEditing
				?
				<>
					<Link
						to={href}
						{...(value?.target && { target: value.target })}
						className={value?.class}
						size={props.size}
						variant={props.variant}
						{...(props.ariaLabel && { ariaLabel: props.ariaLabel })}
						bgColor={props.bgColor}
						color={props.color}
						_hover={{
							bgColor: props.bgColorHover
						}}
						_active={{
							bgColor: props.bgColorHover
						}}
						dataLayer={props.dataLayer}
						{...(!href?.startsWith('/') && { rel: "noopener" })}
					>
						{props.children || value?.text || href || ""}
					</Link>
				</>
				:
				<ChakraLink
					as="span"
					size={props.size}
					variant={props.variant}
					bgColor={props.bgColor}
					color={props.color}
					_hover={{
						bgColor: props.bgColorHover
					}}
					_active={{
						bgColor: props.bgColorHover
					}}
					data-group>
					{/*eslint-disable-next-line*/}
						{/*@ts-ignore*/}
					<SitecoreLink {...(sitecoreProps)} />
				</ChakraLink>
			}
		</>
	);
};



export default RoutableSitecoreLink;