import { type Ref, type SVGProps, forwardRef, memo } from "react"
const SvgComponent = (
	{ fill, transform, ...props }: SVGProps<SVGSVGElement> & {fill?: string, transform?: string},
	ref: Ref<SVGSVGElement>
) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		width={24}
		height={25}
		viewBox="0 0 24 25"
		ref={ref}
		style={{
			padding: "0.15rem",
			transform: transform ?? "unset",
		}}
		{...props}
	>
		<path
			fill={fill ?? "#000"}
			d="M13.592 18.5c.24-2.096 1.218-4.171 3.455-5.002v-.04L3 13.479v-1.957l14.047.02v-.04c-2.237-.83-3.215-2.905-3.455-5.001h2.057c.3 2.807 2.136 5.061 5.351 5.1v1.8c-3.215.02-5.052 2.293-5.351 5.1h-2.057Z"
		/>
	</svg>
)
const ForwardRef = forwardRef(SvgComponent)
const Memo = memo(ForwardRef)
export default Memo
