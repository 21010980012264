export const getCookie = (name: string) => {
	return document.cookie.split("; ").reduce((r, v) => {
		const parts = v.split("=");
		return parts[0] === name ? decodeURIComponent(parts[1]) : r;
	}, "");
};

export const setCookie = (name: string, value: string, days: number): void => {
	const expires = new Date();
	expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Set expiration time in days
	document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
};

export const getFullCookie = (name: string) => {
	return document.cookie.split("; ").reduce((r, v) => {
		const parts = v.split("=");
		return parts[0] === name ? decodeURIComponent(parts as never) : r;
	}, "");
};

export const getOneTrustTargetingConsent = () => {
	const targetingGroup = "C0004";
	let targetingConsent = false;

	const oneTrustCookie = getFullCookie("OptanonConsent");

	if (oneTrustCookie && oneTrustCookie.includes(`${targetingGroup}:1`)) {
		targetingConsent = true;
	}

	return targetingConsent;
}
