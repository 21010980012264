export const splideStyles = {
	'.splide__spinner': {
		display: "none !important"
	},
	"@keyframes splide-loading": {
		from: {
			transform: "rotate(0)",
		},
		to: {
			transform: "rotate(1turn)"
		},
	},
	".splide__track--draggable": {
		WebkitTouchCallout: "none",
		userSelect: "none",
	},
	".splide__track--fade > .splide__list > .splide__slide": {
		margin: "0!important",
		opacity: 0,
		zIndex: 0
	},
	".splide__track--fade > .splide__list > .splide__slide.is-active": {
		opacity: 1,
		zIndex: 1,
	},
	".splide--rtl": {
		direction: "rtl"
	},
	".splide__track--ttb > .splide__list": {
		display: "block"
	},
	".splide__container": {
		boxSizing: "border-box",
		position: "relative"
	},
	".splide__list": {
		backfaceVisibility: "hidden",
		display: "flex",
		height: "100%",
		margin: "0!important",
		padding: "0!important"
	},
	".splide.is-initialized:not(.is-active) .splide__list": {
		display: "block"
	},
	".splide__pagination": {
		alignItems: "center",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		margin: "0",
		pointerEvents: "none"
	},
	".splide__pagination li": {
		display: "inline-block",
		lineHeight: 1,
		listStyleType: "none",
		margin: 0,
		pointerEvents: "auto"
	},
	".splide:not(.is-overflow) .splide__pagination": {
		display: "none"
	},
	".splide__progress__bar": {
		width: 0
	},
	".splide": {
		position: "relative",
		visibility: "hidden"
	},
	".splide.is-initialized, .splide.is-rendered": {
		visibility: "visible"
	},
	".splide__slide": {
		backfaceVisibility: "hidden",
		boxSizing: "border-box",
		flexShrink: 0,
		listStyleType: "none!important",
		margin: 0,
		position: "relative"
	},
	".splide__slide img": {
		verticalAlign: "bottom"
	},
	".splide__sr": {
		clip: "rect(0 0 0 0)",
		border: 0,
		height: "1px",
		margin: "-1px",
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		width: "1px"
	},
	".splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause": {
		display: "none"
	},
	".splide__toggle.is-active .splide__toggle__pause": {
		display: "inline"
	},
	".splide__track": {
		overflow: "hidden",
		position: "relative",
		zIndex: 0
	}
}