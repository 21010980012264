import React, { type FC } from 'react';
import { Link } from "../Components/Link";
import { type LinkVariant } from "../Theme/components/Link";
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react'; // eslint-disable-line no-restricted-imports
import { useSitecoreContext, type LinkFieldValue } from "~/foundation/Jss";
import type { LinkProps as SitecoreLinkProps } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import type { Optional } from "utility-types";
import { IconArrowRight } from "../Components/Icon";
import { Flex, Link as ChakraLink } from "@chakra-ui/react";

export type LinkProps = Optional<SitecoreLinkProps, 'field'> & {
	variant?: LinkVariant;
	color?: string;
	ariaLabel?: string;
	dataLayer?: () => GTMDataLayer;
}

/** React component that turns Sitecore link values that start with / into react-router route links */
const RoutableSitecoreLink: FC<LinkProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	const editable = props.editable ?? true;

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	const value = props.field?.value as LinkFieldValue | undefined;
	const href = value?.href;
	const hasValidHref = props.field && props.field.value && href;
	const isEditing = editable && (props.field?.editableFirstPart || props.field?.editableLastPart);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { variant, ariaLabel, color, ...sitecoreProps } = props;

	return (
		<ChakraLink
			as="span"
			variant={props.variant}
			color={props.color}
			data-group>
			{hasValidHref && !isEditing
				? (
					<>
						<Link
							to={href}
							{...(value?.target && { target: value.target })}
							className={value?.class}
							variant={props.variant}
							{...(props.ariaLabel && { ariaLabel: props.ariaLabel })}
							color={props.color}
							dataLayer={props.dataLayer}
							{...(!href?.startsWith('/') && { rel: "noopener" })}
						>
							{props.children || value?.text || href || ""}
						</Link>
					</>
				)
				: (
					<>
						{/* eslint-disable-next-line */}
						{/* @ts-ignore */}
						<SitecoreLink {...(sitecoreProps)} />
						{props.variant === "primary" &&
							<Flex ms="4">
								<IconArrowRight />
							</Flex>
						}
					</>
				)
			}
		</ChakraLink>
	);
};



export default RoutableSitecoreLink;