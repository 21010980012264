import { type ComponentStyleConfig } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const text = (): ComponentStyleConfig => ({
	baseStyle: {
		lineHeight: 'base',
	},
	sizes: {
		display: {
			fontVariationSettings: "'wght' 70",
			fontSize: ['5rem', null, '6.875rem'],
			lineHeight: "1",
			letterSpacing: ['-0.25rem', null, '-0.34375rem'],
		},
		h1: {
			fontSize: ['2.375rem', null, '4rem'],
			fontWeight: "400",
			lineHeight: "1.1",
		},
		h2: {
			fontSize: ['1.75rem', null, '3rem'],
			fontWeight: "400",
			lineHeight: "1.1",
		},
		h3: {
			fontSize: ['1.25rem', null, '2rem'],
			fontWeight: "400",
			lineHeight: "1.1",
		},
		h4: {
			fontSize: ['1.125rem', null, '1.5rem'],
			fontWeight: "400",
			lineHeight: "1.1",
		},
		bodyLarge: {
			fontSize: '1rem',
			fontWeight: "500",
			lineHeight: "1.5",
		},
		bodySmall: {
			fontSize: '0.875rem',
			fontWeight: "500",
			lineHeight: "1.5",
		},
		cta: {
			fontSize: '0.8125rem',
			fontWeight: "400",
		},
		caption: {
			fontSize: ['0.6875rem', null, '0.8125rem'],
			textTransform: 'uppercase',
			fontWeight: "500",
			lineHeight: "1.1",
			letterSpacing: ['-0.01375rem', null, '0']
		},
	},
	variants: {
		
	},
});
