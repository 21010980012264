/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { type ApiError } from "./types";
import { type ApiResponse } from "./http";

type UseServiceState<T> = {
	result: T | null;
	error: ApiError | null;
	loading: boolean;
};

export function useService<T>(getServiceRequest: () => Promise<ApiResponse<T>>, dependencies: any[] = []): [T | null, ApiError | null, boolean] {
	const [state, setState] = useState<UseServiceState<T>>({
		result: null,
		error: null,
		loading: true
	});

	useEffect(() => {
		const request$ = getServiceRequest();
		if (!request$) return;

		setState({
			result: state.result,
			error: state.error,
			loading: true
		})

		request$
			.then(response => {
				setState({
					result: response.data ?? null,
					error: (response.error as ApiError) ?? null,
					loading: false
				})
			})
			.catch(error => {
				setState({
					result: null,
					error: { message: "Fetch error", data: error },
					loading: false
				});
			});

		// return () => {
		//   we are not able to cancel fetches
		// };

	}, dependencies);

	return [state.result, state.error, state.loading];
}