import { type ComponentStyleConfig, extendTheme } from "@chakra-ui/react";

const getAccordionVariants = () => ({
	defaultAccordion: {
		panel: {
			border: "none",
			padding: ["0.875rem 1rem 1.125rem 0", "1.875rem 5rem 3.125rem 0"],
			color: "AWPrimary.dark",
		},
		icon: {
			boxSize: "2rem",
			"& svg": {
				width: "100%",
				height: "100%",
				color: "AWPrimary.dark",
			},
		},
		button: {
			//py: [4, 9],
			px: 0,
			color: "AWPrimary.dark",
			_expanded: {
				color: "AWPrimary.softDark",
				icon: {
					color: "AWPrimary.softDark",
				},
			},
			_collapsed: {
				color: "AWPrimary.dark",
				icon: {
					color: "AWPrimary.softDark",
				},
			},
			_hover: {
				color: "AWPrimary.softDark",
				bgColor: "transparent",
				icon: {
					color: "AWPrimary.softDark",
				},
			},
		},
	},
});

export const Accordion = (): ComponentStyleConfig => ({
	variants: getAccordionVariants(),
	defaultProps: {
		variant: "defaultAccordion",
	},
});

const theme = extendTheme({
	components: {
		Accordion: Accordion(),
	},
});

export default theme;
