import { type ColorTheme } from "..";

export const getDarkYellowColorTheme = (): ColorTheme => ({
	name: "Dark Yellow",
	ribbon: {
		primaryBg: "var(--chakra-colors-AWPrimary-darkYellow)",
		primaryColor: "var(--chakra-colors-AWFunctional-white)",
		primaryColorAlpha: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.3)",
		primaryColorBigText: "var(--chakra-colors-AWFunctional-white)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-midYellow)",
		quoteSmallText: "var(--chakra-colors-AWFunctional-white)",
		secondaryBg: "var(--chakra-colors-AWPrimary-lightYellow)",
		secondaryColor: "var(--chakra-colors-AWPrimary-lightYellow)",
		midColor: "var(--chakra-colors-AWPrimary-midYellow)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-midYellow)",
		primaryButtonColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonHoverBg: "#E1CF29",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-midYellow)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.08)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.18)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-darkYellow)",
	},
});