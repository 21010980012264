import React, { type FC } from "react";
import { useSitecoreContext, RichText } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type RichTextProps as SitecoreRichTextProps } from "@sitecore-jss/sitecore-jss-react/types/components/RichText";
import type { Optional } from "utility-types";

type RichTextProps = Optional<SitecoreRichTextProps, "field">;

const RichTextWrapper: FC<RichTextProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	return <RichText {...(props as SitecoreRichTextProps)} />;
};

export default RichTextWrapper;
