import { baseColorTheme, type ThemeType } from "./base-color-theme";
import { colors } from "../variables/colors";

export const getDarkBlueColorTheme = (): ThemeType => ({
	name: "Dark blue",
	primaryColor: "var(--chakra-colors-TechPrimary-darkBlue)",
	secondaryColor: "var(--chakra-colors-TechPrimary-green)",
	primaryGradient: colors.TechPrimary.darkBlue.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	linearGradient: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(63.93deg, rgba(0, 51, 160, 0) -3.15%, #0033A0 37.44%)",
	reversedLinearGradient: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(-63.93deg, rgba(0, 51, 160, 0) -3.15%, #0033A0 37.44%)",
	...baseColorTheme
});

export type DarkBlueColorTheme = ReturnType<typeof getDarkBlueColorTheme>;