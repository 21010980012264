import { type ColorTheme } from "..";

export const getLightGreenColorTheme = (): ColorTheme => ({
	name: "Light Green",
	ribbon: {
		primaryBg: "var(--chakra-colors-AWPrimary-lightGreen)",
		primaryColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryColorAlpha: "rgba(var(--chakra-colors-AWFunctionalRgb-dark), 0.3)",
		primaryColorBigText: "var(--chakra-colors-AWPrimary-darkGreen)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-darkGreen)",
		quoteSmallText: "var(--chakra-colors-AWPrimary-dark)",
		secondaryBg: "var(--chakra-colors-AWPrimary-midGreen)",
		secondaryColor: "var(--chakra-colors-AWPrimary-dark)",
		midColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonColor: "var(--chakra-colors-AWFunctional-white)",
		primaryButtonHoverBg: "var(--chakra-colors-AWPrimary-softDark)",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-darkGreen)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.08)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.08)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-midGreen)",
	},
});