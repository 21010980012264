/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { isEditorActive } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line
import { type Rendering } from "../Rendering";
import LazyHydrate from "~/foundation/LazyHydration";

type RenderingProps = {
	rendering: Rendering<unknown>;
	as: string
}

export const lazyHydrate = <P extends object>(InputComponent: React.ComponentType<P>, as: string) => {
	const hydrate = (props: P & RenderingProps) => {
		if (isEditorActive()) {
			return <InputComponent { ...props } />;
		}

		return (
			// overriding style prop from LazyHydrate to avoid "display: contents"
			<LazyHydrate whenVisible style={{}} noWrapper={true} as={as}>
				<InputComponent {...props} />
			</LazyHydrate>
		);
	}

	hydrate.displayName = "hydrate";

	return hydrate;
}