import { Container as ChakraContainer, type ContainerProps } from "@chakra-ui/react";

const Container = ({ children, ...rest }: ContainerProps) => {
	return (
		<ChakraContainer
			variant="responsive"
			maxW={
				{
					base: '100%',
					sm: '100%',
					md: '100%',
					lg: '100%',
					xl: '1184px',
				}
			}
			margin={'0 auto'}
			{...rest}
		>
			{children}
		</ChakraContainer>
	);
};

export { Container };