import { getDefaultColorTheme } from "./color-themes/default-color-theme";
import { getLightPurpleColorTheme } from "./color-themes/lightpurple-theme";
import { getLightBlueColorTheme } from "./color-themes/ligthblue-theme";
import { getDarkBlueColorTheme } from "./color-themes/darkblue-theme";
import { getDarkGreenColorTheme } from "./color-themes/darkgreen-theme";
import { getDarkPurpleColorTheme } from "./color-themes/darkpurple-theme";
import { getDarkRedColorTheme } from "./color-themes/darkred-theme";
import { getDarkYellowColorTheme } from "./color-themes/darkyellow-theme";
import { getMidRedColorTheme } from "./color-themes/midred-theme";
import { getMidPurpleColorTheme } from "./color-themes/midpurple-theme";
import { getMidBlueColorTheme } from "./color-themes/midblue-theme";
import { getMidGreenColorTheme } from "./color-themes/midgreen-theme";
import { getMidYellowColorTheme } from "./color-themes/midyellow-theme";
import { getLightGreenColorTheme } from "./color-themes/lightgreen-theme";
import { getLightRedColorTheme } from "./color-themes/lightred-theme";
import { getLightYellowColorTheme } from "./color-themes/lightyellow-theme";
import { getLightGreyColorTheme } from "./color-themes/ligthgrey-theme";
import { getMidGreyColorTheme } from "./color-themes/midgrey-theme";
import { getDarkGreyColorTheme } from "./color-themes/darkgrey-theme";

export const themes = {
	"theme_01": getDefaultColorTheme,
	"theme_02": getLightGreenColorTheme,
	"theme_03": getLightBlueColorTheme,
	"theme_04": getLightPurpleColorTheme,
	"theme_05": getLightRedColorTheme,
	"theme_06": getLightYellowColorTheme,
	"theme_07": getLightGreyColorTheme,
	"theme_08": getMidGreenColorTheme,
	"theme_09": getMidBlueColorTheme,
	"theme_10": getMidPurpleColorTheme,
	"theme_11": getMidRedColorTheme,
	"theme_12": getMidYellowColorTheme,
	"theme_13": getMidGreyColorTheme,
	"theme_14": getDarkGreenColorTheme,
	"theme_15": getDarkBlueColorTheme,
	"theme_16": getDarkPurpleColorTheme,
	"theme_17": getDarkRedColorTheme,
	"theme_18": getDarkYellowColorTheme,
	"theme_19": getDarkGreyColorTheme,
};

export type ColorThemeId = keyof typeof themes;