import React from "react";
import { useSitecoreContext } from "~/foundation/Jss";
import { Link } from "../Link";
import { Box, Text as ChakraText } from "@chakra-ui/react";

export const BreadcrumbNavigation = () => {
	const { sitecoreContext } = useSitecoreContext();
	const breadcrumbItems = sitecoreContext.custom.breadcrumbItems?.items || [];

	return (
		<Box as="nav">
			{breadcrumbItems.map((breadcrumbItem, index) => {
				return (
					<React.Fragment key={index}>
						<Link
							to={index !== breadcrumbItems.length - 1 ? breadcrumbItem.url : undefined}
							position="relative"
							_after={{
								content: "''",
								position: "absolute",
								height: "1px",
								width: "0%",
								left: 0,
								bottom: "-.25rem",
								bgColor: "currentColor",
								transition: ".5s cubic-bezier(.35,1,.45,1) width"
							}}
							_hover={index !== breadcrumbItems.length - 1 ? {
								_after: {
									width: "100%",
								}
							} : {}}
						>
							<ChakraText
								as="span"
								variant="breadcrumb"
								casing="lowercase"
							>
								{breadcrumbItem.title}
							</ChakraText>
						</Link>
						<ChakraText
							as="span"
							variant="breadcrumb"
							px=".5rem"
						>
							{index !== breadcrumbItems.length - 1 && "/"}
						</ChakraText>
					</React.Fragment>
				)
			})}
		</Box>
	)
}