export const breakpoints = {
	// 480px
	sm: '30rem',
	//  768px
	md: '48rem',
	//  992px    
	lg: '62rem',
	// 1280px
	xl: '80rem',
	// 1536px
	'2xl': '96rem',
	// 1920px and up
	'3xl': '120rem',
	// 2240 and up
	'4xl': '140rem'
};