import { colors } from "../variables/colors";
import { baseColorTheme } from "./base-color-theme";

export const getGreyColorTheme = () => ({
	name: "Grey",
	primaryColor: "var(--chakra-colors-TechPrimary-grey)",
	primaryGradient: colors.TechBase.grey.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	secondaryColor: "var(--chakra-colors-TechPrimary-darkBlue)",
	linearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(63.93deg, rgba(235, 235, 235, 0) -3.15%, #EBEBEB 37.44%)",
	reversedLinearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(-63.93deg, rgba(235, 235, 235, 0) -3.15%, #EBEBEB 37.44%)",
	...baseColorTheme
});

export type GreyColorTheme = ReturnType<typeof getGreyColorTheme>;