import { Grid as ChakraGrid, GridItem as ChakraGridItem, type GridProps as ChakraGridProps, type GridItemProps } from '@chakra-ui/react';

export type GridProps = ChakraGridProps;

export const Grid = ({ children, ...rest }: GridProps) => {
	return (<ChakraGrid templateColumns={["repeat(4, 1fr)", null, "repeat(12, 1fr)"]} gap={["1.5rem", null, "3rem"]} px={["1.25rem", null, "3rem"]} {...rest}>{children}</ChakraGrid>)
}

export const GridItem = ({ children, ...rest }: GridItemProps) => {
	return <ChakraGridItem colSpan={[4, null, 12]} {...rest}>{children}</ChakraGridItem>
};