/* eslint-disable @typescript-eslint/no-unused-vars */
import { extendBaseTheme } from "@chakra-ui/react";
import { type DeepPartial } from "utility-types";
import { getGlobalStyling } from "./styles/global";

import { colors } from "./variables/colors";
import { fonts } from "./variables/fonts";
import zIndex from "./variables/z-index";
import { theme as chakraTheme } from "@chakra-ui/theme";

import { Container } from "./components/Container";
import { Heading } from "./components/Heading";
import { Button } from "./components/Button";
import { text } from "./components/Text";
import { Link } from "./components/Link";
import { Icon } from "./components/Icon";
import { breakpoints } from "./variables/breakpoints";

const { Button: ChakraButton, Heading: ChakraHeading } = chakraTheme.components;

const foundations = (rtl: boolean) => ({
	getGlobalStyling: getGlobalStyling(rtl),
	direction: rtl ? "rtl" : "ltr",
	...fonts(rtl),
	colors,
	breakpoints,
	zIndex,
});

const components = (rtl: boolean) => ({
	Container: {
		...Container(),
	},
	Text: {
		...text(rtl),
	},
	Heading: {
		...ChakraHeading,
		...Heading(),
	},
	Button: {
		...ChakraButton,
		...text(rtl),
		...Button(rtl),
	},
	Link: {
		...Link(rtl),
	},
	Icon: {
		...Icon(rtl),
	},
});

const defaultTheme = (rtl: boolean) => ({
	name: "Default",
	...foundations(rtl),
	components: components(rtl),
	styles: getGlobalStyling(rtl),
});

export type Theme = DeepPartial<ReturnType<typeof defaultTheme>> & {
	name: string;
};

export function getDefaultTheme(rtl = false) {
	const theme = defaultTheme(rtl);
	return extendBaseTheme(theme) as Theme;
}

// used to generate chakra-types
export default getDefaultTheme(false);
