import { format as formatDate } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { arSA } from "date-fns/locale";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateFormatter = (date: Date, isRtl: boolean, format?: string) => {
	return formatDate(
		toZonedTime(date, localTimeZone),
		format || "MMMM dd, yyyy",
		{ locale: isRtl ? arSA : undefined } // this is taken from AramcoLife. Bjørn made this.
	)
}