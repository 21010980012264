/* eslint-disable @typescript-eslint/no-explicit-any, no-async-promise-executor */
import {
	type ComponentProps,
	type ComponentType,
	createContext,
	type ReactElement,
	type ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'

type LoaderType<T, P> = () => Promise<LoadableComponent<T, P>>
type LoaderTypeOptional<T, P> = () => Promise<LoadableComponent<T, P>> | undefined

const ALL_INITIALIZERS: LoaderType<any, any>[] = []
const READY_INITIALIZERS: LoaderTypeOptional<any, any>[] = []
const CaptureContext = createContext<((moduleId: string) => any) | undefined>(undefined)
CaptureContext.displayName = 'Capture'

export function Capture({ report, children }: {
	report(moduleId: string): any
	children: ReactNode
}) {
	return (
		<CaptureContext.Provider value={report}>
			{children}
		</CaptureContext.Provider>
	);
}

Capture.displayName = 'Capture'

type LoadableOptions<T, P> = {
	loading: ComponentType<{
		error?: Error | unknown
		retry(): any
	}>;
	loader(): Promise<T>;
	render?(loaded: T, props: P): ReactElement;
	modules: string[];
	moduleId: string;
}

export type LoadableComponent<T, P> = ComponentType<T extends {default: ComponentType<infer Props>}
	? Props
	: P // this conditional branch is not 100% correct. It should be never if render property is not provided
>

const isModuleReady = (moduleId: string) => {
	if (typeof window !== 'undefined') {
		const isReady = window.loadedModules?.some((id: string) => id === moduleId) || false;
		return isReady;
	}

	return false;
}

interface LoadState<T, P> {
	promise: Promise<LoadableComponent<T, P>>
	loaded?: LoadableComponent<T, P>
	error?: Error | unknown
}

const load = <T, P>(loader: LoaderType<T, P>) => {
	const state = {
		loaded: undefined,
		error: undefined,
	} as LoadState<T, P>

	state.promise = new Promise<LoadableComponent<T, P>>(async (resolve, reject) => {
		try {
			resolve(state.loaded = await loader())
		} catch (e) {
			reject(state.error = e)
		}
	})

	return state
}

type LoadComponent<P> = {
	// __esModule: true
	default: ComponentType<P>
} | ComponentType<P>

const resolve = <P, >(obj: LoadComponent<P>): ComponentType<P> => (obj as any)?.__esModule ? (obj as any).default : obj
const defaultRenderer = <P, T extends {default: ComponentType<P>}>(
	loaded: T,
	props: T extends {default: ComponentType<infer P>} ? P : never
) => {
	const Loaded: any = resolve(loaded)
	return <Loaded {...props}/>
}

type LoadableState<T, P, > = {
	error?: Error | unknown
	loaded?: LoadableComponent<T, P>
}

function createLoadableComponent<T, P>(
	{
		loading: Loading,
		loader,
		moduleId,
		render = defaultRenderer as (loaded: T, props: P) => ReactElement,
		...opts
	}: LoadableOptions<T, P>
): LoadableComponent<T, P> & {
		displayName: string
		preload: LoaderType<T, P>
	} {
	if (!Loading) {
		throw new Error('react-loadable requires a `loading` component')
	}

	let loadState: LoadState<T, P>

	const init = () => {
		if (!loadState) {
			loadState = load(loader as any)
		}

		return loadState.promise
	}

	ALL_INITIALIZERS.push(init)

	if (moduleId) {
		READY_INITIALIZERS.push(() => {
			if (isModuleReady(moduleId)) {
				return init()
			}
		})
	}

	const LoadableComponent = (props: ComponentProps<LoadableComponent<T, P>>) => {
		init()

		const report = useContext(CaptureContext)

		// eslint-disable-next-line prefer-const
		let [state, setState] = useState<LoadableState<T, P>>({
			error: loadState.error,
			loaded: loadState.loaded
		})

		const mountedRef = useRef<boolean>(false)
		useEffect(() => {
			mountedRef.current = true
			return () => void (mountedRef.current = false)
		}, [])

		const loadModule = useCallback(async () => {
			if (report && Array.isArray(opts.modules)) {
				for (const moduleName of opts.modules) {
					report(moduleName)
				}
			}
			if (loadState.error || loadState.loaded) {
				return
			}

			try {
				await loadState.promise
			} catch {
				// empty
			} finally {
				const newState = {
					error: loadState.error,
					loaded: loadState.loaded,
				}
				if (mountedRef.current) {
					setState(newState)
				} else {
					state = newState
				}
			}
		}, [report, mountedRef])

		const retry = useCallback(async () => {
			if (!mountedRef.current) return
			setState({ error: undefined, loaded: undefined })
			loadState = load(loader as any)
			await loadModule()
		}, [loadModule])

		const firstStateRef = useRef<LoadableState<T, P> | undefined>(state)
		if (firstStateRef.current) {
			loadModule()
			firstStateRef.current = undefined
		}

		return !state.loaded || state.error
			? <Loading error={state.error} retry={retry}/>
			: render(state.loaded as any, props as any)
	}

	LoadableComponent.preload = init
	LoadableComponent.displayName = `LoadableComponent(${Array.isArray(opts.modules) ? opts.modules.join('-') : ''})`

	return LoadableComponent as any
}

const flushInitializers = async <T, P>(initializers: (LoaderType<T, P> | LoaderTypeOptional<T, P>)[]): Promise<void> => {
	const promises = []

	while (initializers.length) {
		promises.push(initializers.pop()!())
	}

	await Promise.all(promises)

	if (initializers.length) {
		return flushInitializers(initializers)
	}
}

export const preloadAll = () => {
	return flushInitializers(ALL_INITIALIZERS)
}
export const preloadReady = () => {
	return flushInitializers(READY_INITIALIZERS)
}

export const Loadable = <T, P>(opts: LoadableOptions<T, P>) => createLoadableComponent(opts)