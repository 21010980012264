import type { RecursiveObject } from "@chakra-ui/react";
import { rem } from "polished";

export const mapPxToRem = (
	values: object,
	base: string | number = "16px"
): RecursiveObject =>
	Object.assign(
		{},
		...Object.entries(values).map(([key, value]: [string, string]) => ({
			[key]: rem(value, base),
		}))
	);
export const pxToRem = (
	value: string,
	base: string | number = "16px"
): string => rem(value, base);
