import type { ChakraTheme } from "@chakra-ui/react";

export const sizes: ChakraTheme["sizes"] = {
	// base 1 = '4px'
	contentWrapperWidthXL: '98rem',//max content width of 1440px
	modulePY__SM: "2.5rem",
	modulePY__LG: "4rem",
	articleCornerSMWidth: "2.59375rem",
	articleCornerSMHeight: "3rem",
	articleCornerLGWidth: "3.24375rem",
	articleCornerLGHeight: "3.75rem",
	articleCornerXLWidth: "6.875rem",
	articleCornerXLHeight: "7.9375rem",
	articleCardRoundedCorner: "0.5rem",
	articleCornerBoxSpacing: "240px",
	headerHeightBase: "3.75rem",
	headerHeightLG: "4.375rem",
	600: "37.5rem",
	880: "55rem",
};