import React from "react";
import { Icon } from "@chakra-ui/icon";
import { type IconProps } from "@chakra-ui/react";

export const IconMenu = (props: IconProps) => (
	<Icon viewBox="0 0 18 16" w="18px" h="16px" {...props}>
		<path
			d="M1 1H17M1 7.90909H11.2452M1 14.8182H17"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round" />
	</Icon>
);

export const IconClose = (props: IconProps) => (
	<Icon viewBox="0 0 15 15" w="15px" h="15px" {...props}>
		<path
			d="M0.68457 13.6935L13.4426 0.935547M13.4426 13.6935L0.68457 0.935547"
			stroke="currentColor"
			strokeWidth="1.19606"
			strokeLinecap="round"
			strokeLinejoin="round" />
	</Icon>
);

export const IconCloseSmall = (props: IconProps) => (
	<Icon viewBox="0 0 15 15" w="15px" h="15px" {...props}>
		<path
			d="M0.68457 13.6935L13.4426 0.935547M13.4426 13.6935L0.68457 0.935547"
			stroke="currentColor"
			strokeWidth="2.2px"
			strokeLinecap="round"
			strokeLinejoin="round" />
	</Icon>
);

export const IconAccordion = (props: IconProps) => (
	<Icon viewBox="0 0 10 6" w="10px" h="6px" {...props}>
		<path
			d="M0.833417 1.03822C0.989552 0.883016 1.20076 0.795898 1.42092 0.795898C1.64107 0.795898 1.85228 0.883016 2.00842 1.03822L5.00008 3.98823L7.95008 1.03823C8.10622 0.883016 8.31743 0.795898 8.53758 0.795898C8.75774 0.795898 8.96895 0.883016 9.12508 1.03823C9.20319 1.11569 9.26519 1.20786 9.30749 1.30941C9.3498 1.41096 9.37158 1.51988 9.37158 1.62989C9.37158 1.7399 9.3498 1.84882 9.30749 1.95037C9.26519 2.05192 9.20319 2.14409 9.12508 2.22156L5.59175 5.75489C5.51428 5.833 5.42211 5.89499 5.32056 5.9373C5.21901 5.97961 5.11009 6.00139 5.00008 6.00139C4.89007 6.00139 4.78115 5.97961 4.6796 5.9373C4.57805 5.89499 4.48589 5.833 4.40842 5.75489L0.833417 2.22156C0.75531 2.14409 0.693315 2.05192 0.651008 1.95037C0.608701 1.84882 0.586918 1.7399 0.586918 1.62989C0.586918 1.51988 0.608701 1.41096 0.651008 1.30941C0.693315 1.20786 0.75531 1.11569 0.833417 1.03822Z"
			fill="currentColor"
		/>
	</Icon>
);

export const IconWatch = (props: IconProps) => (
	<Icon viewBox="0 0 20 20" w="20px" h="20px" {...props}>
		<path
			d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10 5.625V10H14.375"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</Icon>
);

export const IconPlay = (props: IconProps) => (
	<Icon viewBox="0 0 58 66" fill="none" w="58px" h="66px" {...props}>
		<path d="M57.3418 32.671L0.835294 65.295L0.835296 0.0469565L57.3418 32.671Z" fill="currentColor" />
	</Icon>
);

export const IconArrowDown = (props: IconProps) => (
	<Icon viewBox="0 0 20 33" w="20px" h="33px" fill="none" {...props}>
		<path
			d="M10 1L10 20"
			stroke="currentColor"
			strokeOpacity="0.4"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18 23.5545L10 32L2 23.5545M10 20V31.7635"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</Icon>
);

export const IconSearch = (props: IconProps) => (
	<Icon viewBox="0 0 20 20" w="20px" h="20px" fill="none" {...props}>
		<path d="M12.3523 12.8523C11.7348 13.4698 11.0017 13.9596 10.1948 14.2938C9.38803 14.628 8.52329 14.8 7.65 14.8C5.88631 14.8 4.19486 14.0994 2.94774 12.8523C1.70062 11.6051 1 9.91369 1 8.15C1 6.38631 1.70062 4.69486 2.94774 3.44774C4.19486 2.20062 5.88631 1.5 7.65 1.5C8.52329 1.5 9.38803 1.67201 10.1948 2.0062C11.0017 2.34039 11.7348 2.83023 12.3523 3.44774C12.9698 4.06525 13.4596 4.79834 13.7938 5.60515C14.128 6.41197 14.3 7.27671 14.3 8.15C14.3 9.02329 14.128 9.88803 13.7938 10.6948C13.4596 11.5017 12.9698 12.2348 12.3523 12.8523ZM12.3523 12.8523L17 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconLearningCenter = (props: IconProps) => (
	<Icon viewBox="0 0 16 17" fill="none" w="16" h="17" {...props}>
		<path d="M2 4.5V9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M3.38672 14C3.88701 13.2326 4.57083 12.6022 5.37624 12.1659C6.18165 11.7295 7.0832 11.501 7.99922 11.501C8.91524 11.501 9.81679 11.7295 10.6222 12.1659C11.4276 12.6022 12.1114 13.2326 12.6117 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M14 4.5L8 6.5L2 4.5L8 2.5L14 4.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M10.5813 5.6377C11.0399 6.13856 11.3429 6.76204 11.4534 7.43213C11.5639 8.10222 11.477 8.78998 11.2034 9.41155C10.9297 10.0331 10.4812 10.5617 9.9124 10.9327C9.3436 11.3038 8.67914 11.5014 8 11.5014C7.32087 11.5014 6.6564 11.3038 6.08761 10.9327C5.51881 10.5617 5.07027 10.0331 4.79664 9.41155C4.52302 8.78998 4.43615 8.10222 4.54661 7.43213C4.65707 6.76204 4.9601 6.13856 5.41875 5.6377" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconListChecks = (props: IconProps) => (
	<Icon w="16" h="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path d="M8 8H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M8 4H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M8 12H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M5.75 3L3.58125 5L2.5 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M5.75 7L3.58125 9L2.5 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M5.75 11L3.58125 13L2.5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconBook = (props: IconProps) => (
	<Icon w="16" h="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path d="M3.00001 13.5C2.99919 13.3028 3.03742 13.1074 3.11251 12.925C3.1876 12.7426 3.29806 12.577 3.43751 12.4375C3.57696 12.2981 3.74265 12.1876 3.92501 12.1125C4.10737 12.0374 4.3028 11.9992 4.50001 12H13V2.00001H4.50001C4.3028 1.99919 4.10737 2.03742 3.92501 2.11251C3.74265 2.1876 3.57696 2.29806 3.43751 2.43751C3.29806 2.57696 3.1876 2.74265 3.11251 2.92501C3.03742 3.10737 2.99919 3.3028 3.00001 3.50001V13.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M3 13.5V14H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconChevronDown = (props: IconProps) => (
	<Icon viewBox="0 0 18 9" w="18" h="9" fill="none" {...props}>
		<path d="M16.9181 0.949219L10.3981 7.46922C9.62812 8.23922 8.36813 8.23922 7.59813 7.46922L1.07812 0.949219" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconCheckmark = (props: IconProps) => (
	<Icon viewBox="0 0 12 9" w="12" h="9" fill="none" {...props}>
		<path d="M1 5.375L3.72727 8L11 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);
export const IconClock = (props: IconProps) => (
	<Icon viewBox="0 0 40 40" w="40px" h="40px" fill="none" {...props}>
		<path d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 11.25V20H28.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconWarmUp = (props: IconProps) => (
	<Icon viewBox="0 0 40 40" w="40px" h="40px" fill="none" {...props}>
		<path d="M28.0312 23.125C27.762 24.8267 26.9608 26.3993 25.7426 27.6176C24.5243 28.8358 22.9517 29.637 21.25 29.9062" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M11.25 10.2188C8.84375 13.8438 6.875 18 6.875 21.875C6.875 25.356 8.25781 28.6944 10.7192 31.1558C13.1806 33.6172 16.519 35 20 35C23.481 35 26.8194 33.6172 29.2808 31.1558C31.7422 28.6944 33.125 25.356 33.125 21.875C33.125 13.75 27.5 7.5 22.9375 2.95312L17.5 14.375L11.25 10.2188Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconClockHalf = (props: IconProps) => (
	<Icon viewBox="0 0 40 40" w="40px" h="40px" fill="none" {...props}>
		<path d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 11.25V14.8707V18.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 18.75L21.2069 23.5776L22.5 28.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconVisualAnalysis = (props: IconProps) => (
	<Icon viewBox="0 0 40 40" w="40px" h="40px" fill="none" {...props}>
		<path d="M20 8.75C7.5 8.75 2.5 20 2.5 20C2.5 20 7.5 31.25 20 31.25C32.5 31.25 37.5 20 37.5 20C37.5 20 32.5 8.75 20 8.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 26.25C23.4518 26.25 26.25 23.4518 26.25 20C26.25 16.5482 23.4518 13.75 20 13.75C16.5482 13.75 13.75 16.5482 13.75 20C13.75 23.4518 16.5482 26.25 20 26.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconRecipe = (props: IconProps) => (
	<Icon viewBox="0 0 20 20" w="20px" h="20px" fill="none" {...props}>
		<path d="M6.5625 2.5V5.625" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M6.5625 9.0625V17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M8.75 2.5L9.375 6.25C9.375 6.99592 9.07868 7.71129 8.55124 8.23874C8.02379 8.76618 7.30842 9.0625 6.5625 9.0625C5.81658 9.0625 5.10121 8.76618 4.57376 8.23874C4.04632 7.71129 3.75 6.99592 3.75 6.25L4.375 2.5"
			stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M15.9375 12.5H11.5625C11.5625 12.5 12.5 3.75 15.9375 2.5V17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconArrowUpRight = (props: IconProps) => (
	<Icon w="16" h="17" viewBox="0 0 16 17" fill="none" {...props}>
		<path d="M4 12.5L12 4.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M5.5 4.5H12V11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>

);

export const IconMapPin = (props: IconProps) => (
	<Icon w="32" h="32" viewBox="0 0 32 32" fill="none" {...props}>
		<path d="M16 17C18.2091 17 20 15.2091 20 13C20 10.7909 18.2091 9 16 9C13.7909 9 12 10.7909 12 13C12 15.2091 13.7909 17 16 17Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M26 13C26 22 16 29 16 29C16 29 6 22 6 13C6 10.3478 7.05357 7.8043 8.92893 5.92893C10.8043 4.05357 13.3478 3 16 3C18.6522 3 21.1957 4.05357 23.0711 5.92893C24.9464 7.8043 26 10.3478 26 13V13Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</Icon>
);

export const IconMail = (props: IconProps) => (
	<Icon w="15" h="11" viewBox="0 0 15 11" fill="none" {...props}>
		<path d="M1.5 1H13.5V9.5C13.5 9.63261 13.4473 9.75979 13.3536 9.85355C13.2598 9.94732 13.1326 10 13 10H2C1.86739 10 1.74021 9.94732 1.64645 9.85355C1.55268 9.75979 1.5 9.63261 1.5 9.5V1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M13.5 1L7.5 6.5L1.5 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
);

export const IconRequired = (props: IconProps) => (
	<Icon w="13" h="12" viewBox="0 0 13 12" fill="none" {...props}>
		<path d="M1.25 6.95625V1.25C1.25 1.11739 1.30268 0.990215 1.39645 0.896447C1.49021 0.802679 1.61739 0.75 1.75 0.75H11.25C11.3826 0.75 11.5098 0.802679 11.6036 0.896447C11.6973 0.990215 11.75 1.11739 11.75 1.25V10.75C11.75 10.8826 11.6973 11.0098 11.6036 11.1036C11.5098 11.1973 11.3826 11.25 11.25 11.25H6.975" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M6.5 7.5L2.5 11.5L0.5 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
	</Icon>
);


