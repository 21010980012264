/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const getButtonVariants = (isRtl: boolean) => ({
	lg: {
		gap: 5,
	},
	sm: {
		gap: 3,
	},
});

export type ButtonVariant = keyof (ReturnType<typeof getButtonVariants>);

export const Button = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: 'manifa',
		fontWeight: '600',
		h: "fit-content",
		w: "fit-content",
		display: "inline-flex",
		gap: 5,
	},
	variants: getButtonVariants(isRtl),
});