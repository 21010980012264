import type { ReactNode } from "react";
import { Text, type TextProps } from "@chakra-ui/react";
import { useColorTheme } from "../../theme/ColorThemeContext";
import { colors } from "../../theme/variables/colors";

type BreadcrumbProps = TextProps & {
	children: ReactNode;
	isActive?: boolean;
}

export const Breadcrumb = ({
	children,
	isActive,
	...rest
}: BreadcrumbProps) => {
	const { primaryColor } = useColorTheme();

	return (
		<Text
			variant={"breadcrumb"}
			color={isActive ? primaryColor : colors.TechBase.grey}
			letterSpacing={".04em"}
			_hover={{
				textDecoration: "underline"
			}}
			{...rest}
		>
			{children}
		</Text>
	);
}