import React, { type FC } from 'react';
import { useSitecoreContext } from '../useSitecoreContext';

// taken from https://sitecore.stackexchange.com/questions/24307/edit-frame-with-sitecore-jss

export type FieldEditFrameProps = {
	itemId?: string;
	fields?: string[];
	header?: string;
	icon?: string;
	disabledIcon?: string;
	tooltip?: string;
	command?: string;
	commandDisplayName?: string;
	databaseName?: string;
	language?: string;
	hideIfNotPageEditing?: boolean;
	children: any; // eslint-disable-line
};

const FieldEditFrameWrapper: FC<FieldEditFrameProps> = ({
	itemId,
	fields,
	header,
	icon,
	disabledIcon,
	tooltip,
	command,
	commandDisplayName,
	databaseName,
	language,
	children
}) => {
	// ensure fields and itemId are configured
	if (!itemId || !fields) {
		return null;
	}

	// item uri for edit frame target
	const contextItemUri = `sitecore://${databaseName}/${itemId}?lang=${language}`;

	// click action for edit frame command
	const clickCommandAction = `webedit:fieldeditor(command=${command},fields=${fields.join(
		"|"
	)},id=${itemId})`;

	// click for command for the edit frame
	const clickCommand = {
		click: `javascript:Sitecore.PageModes.PageEditor.postRequest('${clickCommandAction}',null,false)`,
		header: header,
		icon: icon,
		disabledIcon: disabledIcon,
		isDivider: false,
		tooltip: tooltip || `Edit the following fields: ${fields.join(", ")}`,
		type: null
	};

	// command data that is serialized for the edit frame
	const commandData = {
		commands: [clickCommand],
		contextItemUri: contextItemUri,
		custom: {},
		displayName: commandDisplayName || 'Edit Properties',
		expandedDisplayName: ""
	};

	// edit frame attributes
	const divAttrs = {
		'sc_item': contextItemUri,
		'sc-part-of': 'editframe'
	}

	let isEmpty = true;
	if (typeof children === "object" && !Array.isArray(children)) {
		if (children?.props?.field?.value?.src && children?.props?.pageEditing) {
			isEmpty = false;
		}
	}

	return (
		<div className={`scLooseFrameZone scEnabledChrome ${(isEmpty ? "scEmptyPlaceholder" : "")}`} {...divAttrs} style={{ width: "100%", height: "100%" }}>
			<span className="scChromeData">{JSON.stringify(commandData)}</span>
			{children}
		</div>
	);
};

const FieldEditFrame: React.FC<FieldEditFrameProps> = ({
	children,
	itemId = "",
	fields = [],
	hideIfNotPageEditing,
	databaseName,
	language,
	icon = "/temp/iconcache/people/16x16/cubes_blue.png",
	disabledIcon = "/temp/cubes_blue_disabled16x16.png",
	command = "{70C4EED5-D4CD-4D7D-9763-80C42504F5E7}",
	...otherProps
}) => {
	const { sitecoreContext } = useSitecoreContext();

	// check if we're in experience editor and configured properly
	const shouldRender = sitecoreContext.pageEditing && itemId && fields && fields.length;

	// hide if not in page editing mode and prop is passed telling us to hide
	if (!shouldRender || hideIfNotPageEditing) {
		return <>{children}</>;
	}

	// build the props for our edit frame
	const wrapperProps = {
		itemId: itemId || sitecoreContext.itemId,
		fields,
		databaseName: databaseName || sitecoreContext.route?.databaseName || "master",
		language: language || sitecoreContext.language,
		sitecoreContext,
		icon,
		disabledIcon,
		command,
		...otherProps
	}

	return (
		<FieldEditFrameWrapper {...wrapperProps}>
			{children}
		</FieldEditFrameWrapper>
	);
}

export { FieldEditFrame };