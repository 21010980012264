import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { checkboxAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
	control: {
		_checked: {
			backgroundColor: "AWPrimary.dark",
			borderColor: "AWPrimary.dark",
			fill: "AWFunctional.white",
			color: "AWFunctional.white",
		},
		border: "1px solid",
		borderColor: "AWFunctional.darkGray",
		h: "1.5625rem",
		w: "1.5625rem",
		overflow: "hidden",
		borderRadius: "0.25rem",
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "AWPrimary.dark",
		}
	},
	icon: {
		color: "AWFunctional.white",
		h: "auto",
		w: "12px",
		_checked: {
			fill: "AWFunctional.white",
		},
	},
	label: {
		ms: 2,
	},
});

const sizes = {
	md: definePartsStyle({
		label: defineStyle({
			fontFamily: "body",
			fontSize: ".9375rem",
			fontWeight: "600",
			lineHeight: "normal",
			letterSpacing: "-0.02em",
		})
	})
}

export const Checkbox = defineMultiStyleConfig({ sizes, baseStyle })
