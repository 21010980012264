/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Container = (rtl = false): ComponentStyleConfig => ({
	baseStyle: {
		maxW: "100%",
	},
	variants: {
		responsive: {
			// Up to 479px
			sm: { maxW: "80%" },
			// 480px - 767px
			md: { maxW: "90%" },
			// 768px - 991px
			lg: { maxW: "960px" },
			// 992px - 1279px
			xl: { maxW: "1152px" },
			// 1280px - 1535px
			"2xl": { maxW: "1184px" },
			// 1536px - 1919px
			"3xl": { maxW: "1350px" },
			// 1920px and up
			"4xl": { maxW: "1440px" },
		},
	},
});
