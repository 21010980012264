import { Box } from "@chakra-ui/react";
import { sc } from "../../Jss";
import { type ImageProps } from "./ImageTypes";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import BundleIcon from "../ui/bundle-icon";
import { useColorTheme } from "../../theme/ColorThemeContext";

const ImageComponent = ({ fields, itemId, fieldName, srcSet, sizes, isLazy = false, editable = true }: ImageProps) => {
	const { sitecoreContext } = useSitecoreContext();
	const { primaryColor } = useColorTheme();

	return (
		<Box w="100%" h="100%" bg="TechBase.lightGrey" boxShadow={{ lg: "0px -10px 50px 0px #00000026" }} overflow="hidden" {...(!fields?.[fieldName ?? "image"]?.value?.src && sitecoreContext.pageEditing) ? { position: "relative", display: "flex", justifyContent: "center", alignItems: "center" } : {}}>
			<sc.AdvanceImage
				fields={fields}
				itemId={itemId}
				fieldName={fieldName ?? "image"}
				srcSet={srcSet}
				sizes={sizes}
				lazy={isLazy}
				editable={editable}
				sx={{
					objectFit: "cover",
					objectPosition: "center center",
					width: "100%",
					height: "100%",
					...!fields?.[fieldName ?? "image"]?.value?.src && sitecoreContext.pageEditing ? { position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 } : {},
				}}
			/>
			{!fields?.[fieldName ?? "image"]?.value?.src && sitecoreContext.pageEditing && <BundleIcon name="add-image" fill={primaryColor ?? "black"} size="lg" />}
		</Box>
	)
}

export default ImageComponent;
