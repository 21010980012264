/**
 * Used in backend to whitelist sizes
 * When sizes are changed or added, run `npm run build`
 */

/**
 * Values can be:
 * w (width)
 * h (height)
 * mw (max width)
 * mh (max height)
 * cw (crop width)
 * ch (crop height)
 */
export const imageSizes = {
	none: {
		mw: 10,
	},
	partners: {
		sm: {
			mw: 155,
		},
		md: {
			mw: 310,
		}

	},
	defaultWide: { //16:9
		sm: {
			cw: 384,
			ch: 216,
		},
		md: {
			cw: 768,
			ch: 432,
		},
		lg: {
			cw: 1152,
			ch: 648,
		},
		xl: {
			cw: 1440,
			ch: 810,
		}
	},
	defaultWideParallax: { //215 : 125
		sm: {
			cw: 384,
			ch: 223,
		},
		md: {
			cw: 768,
			ch: 447,
		},
		lg: {
			cw: 1152,
			ch: 670,
		},
		xl: {
			cw: 1440,
			ch: 837,
		},
		xxl: {
			cw: 1760,
			ch: 1023,
		}
	},
	wide: { //215 : 100
		sm: {
			cw: 384,
			ch: 179,
		},
		md: {
			cw: 768,
			ch: 357,
		},
		lg: {
			cw: 1152,
			ch: 536,
		},
		xl: {
			cw: 1440,
			ch: 670,
		}
	},
	imageCarousel: {
		base: {
			cw: 350,
			ch: 466,
		},
		md: {
			cw: 700,
			ch: 932,
		},
	},
	thumbnail: {
		base: {
			cw: 78,
			ch: 52,
		},
	}

}