import React from "react";
import { Box, type BoxProps, forwardRef } from "@chakra-ui/react";

export const ContentWrapper = forwardRef<BoxProps, "div">((props, ref) => (
	<Box
		ref={ref}
		maxW="contentWrapperWidthXL"
		mx="auto"
		px={["var(--chakra-sizes-pageMarginSM)", null, null, "var(--chakra-sizes-pageMarginLG)"]}
		{...props}
	/>
));