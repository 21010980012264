import React, { type FC, useState } from "react";
import { type ColorTheme } from "./color-themes/default-color-theme";

// eslint-disable-next-line
export const ColorThemeContext = React.createContext<[ColorTheme, React.Dispatch<React.SetStateAction<ColorTheme>>]>(null as any);

type ColorThemeProviderProps = {
	colorTheme: ColorTheme;
	children: React.ReactNode;
}

export const ColorThemeProvider: FC<ColorThemeProviderProps> = ({ colorTheme, children }) => {
	const colorThemeState = useState<ColorTheme>(colorTheme);

	return (
		<ColorThemeContext.Provider value={colorThemeState}>
			{children}
		</ColorThemeContext.Provider>
	);
};