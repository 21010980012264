export default {
	templateIds: {
		pageTypes: {
			learningCenterSectionTemplateId: "BF17E413-FA45-4FB3-BC9D-37CE0962F595",
			storySectionTemplateId: "57C5380B-2A3B-4F81-A60B-D3C4B5D78AAA",
			storyCategoryTemplateId: "FF234796-0B2D-4613-98FE-C472CF0B99A6",
		},
	},
	templateNames: {
		learningCenterSectionTemplateName: "Learning Center Section Page",
	},
	placeholderNames: {
		main: "page-main",
		contentSection: "aw-content-section-main",
	},
};
