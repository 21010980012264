import React, { type FC } from "react";
import { DateField } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type DateFieldProps as SitecoreDateFieldProps } from "@sitecore-jss/sitecore-jss-react/types/components/Date";
import { useSitecoreContext } from "../useSitecoreContext";
import { dateFormatter } from "~/foundation/Utils/dateFormatter";

type DateFieldProps = Partial<SitecoreDateFieldProps> & {
	format?: string;
};

// To avoid deprecation warning...
DateField.defaultProps = undefined;

const DateWrapper: FC<DateFieldProps> = ({
	editable = true,
	...props
}) => {
	const { sitecoreContext } = useSitecoreContext();
	const newProps = { editable, ...props };

	if (!newProps?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	if (!newProps?.render) {
		newProps.render = date => {
			if (!date) {
				return "";
			}

			// if date field in Sitecore is empty, date will be januar 1, 0001
			return dateFormatter(
				date,
				sitecoreContext.custom.settings.isRtl,
				props.format || sitecoreContext.custom.settings.dateFormat
			)
		}
	}

	return <DateField {...newProps as SitecoreDateFieldProps} />;
};

export default DateWrapper;