import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
	field: {
		_placeholder: {
			color: "primary.greyDark"
		},
	},
});

const newsletter = definePartsStyle({
	field: {
		_placeholder: {
			color: "primary.greyDark",
			pb: 0,
		},
		borderRadius: 0,
		px: 0,
		pb: 2,
		height: "full",
		borderBottom: 1,
		borderStyle: "solid",
		borderColor: "primary.dark",
		_placeholderShown: {
			borderStyle: "dashed",
			borderColor: "primary.dark"
		},
		_hover: {
			color: "primary.dark",
			borderStyle: "solid",
		},
		_focus: {
			color: "primary.dark",
			borderStyle: "solid"
		},
		_invalid: {
			color: "primary.wrong",
		},
		_autofill: {
			textFillColor: "primary.dark",
			boxShadow: "0 0 0px 1000px #FFF inset",
			transition: "background-color 5000s ease-in-out 0s",
		},
		transition: "border 0.5s ease-in-out"
	},
});

export const Input = defineMultiStyleConfig({ baseStyle, variants: { newsletter } })
