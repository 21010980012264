import { keyframes } from "@chakra-ui/react";

export const groupHoverAfterArrowStyleCards = (isRtl = false) => (
	{
		transform: !isRtl ? "translateX(8px)" : "translateX(-8px) scaleX(-1)",
	}
);

export const groupHoverBeforeArrowStyle = {
	width: "40px",
	mr: "16px",
}

export const groupHoverArrowStyle = (isRtl = false) => (
	{
		transform: !isRtl ? "translateX(8px)" : "translateX(-8px) scaleX(-1)",
	}
)

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const reveal = keyframes`
  to { opacity: 1; transform: translate(0); }
`;