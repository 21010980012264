/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";
import { getLinkSizes, getLinkVariants, LinkBaseStyle } from "./Link";

export const Button = (): ComponentStyleConfig => {
	return {
		...LinkBaseStyle,
		sizes: getLinkSizes(),
		variants: getLinkVariants(),
		defaultProps: {
			// variant: 'unstyled',
		},
	};
};
