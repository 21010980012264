/* eslint-disable @typescript-eslint/no-unused-vars */
export const getDefaultColorTheme = () => ({
	name: "Default",
	ribbon: {
		primaryBg: "transparent",
		primaryColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryColorAlpha: "rgba(var(--chakra-colors-AWFunctionalRgb-dark), 0.3)",
		primaryColorBigText: "var(--chakra-colors-AWPrimary-dark)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-midPurple)",
		quoteSmallText: "var(--chakra-colors-AWPrimary-midPurple)",
		secondaryBg: "var(--chakra-colors-AWFunctional-lighterGray)",
		secondaryColor: "var(--chakra-colors-AWPrimary-dark)",
		midColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonColor: "var(--chakra-colors-AWFunctional-white)",
		primaryButtonHoverBg: "var(--chakra-colors-AWPrimary-softDark)",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-dark)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.18)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.08)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-midBlue)",
	},
});

export type ColorTheme = ReturnType<typeof getDefaultColorTheme>;