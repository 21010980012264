import React, { type FC } from "react";
import { useTranslation } from "~/foundation/Dictionary";
import { type GlobalDictionary } from "../../../dictionary";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { sc } from "../../Jss";
import { type Article, type ArticleExternal } from "../../../generated-types";
import { type Tag } from "~/foundation/SitecoreContent/generated-types";
import { type ItemReference } from "~/foundation/Jss";
import { getArticleTheme } from "../../Article/getArticleTheme";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { ChakraRouterLink } from "../Link/ChakraRouterLink";
import { AspectRatio, Box, Flex, Heading, Text as ChakraText, Link, useMediaQuery } from "@chakra-ui/react";
import { articleListImageMotion, articleListArrowMotion } from "../../Theme/styles/motions";
import { imageSizes } from "../../Theme";
import { breakpoints } from "../../Theme/variables/breakpoints";
import { IconArrowRight } from "../Icon";
import cursor from "~/foundation/Assets/svg/cursors/hover-cursor.svg";
import cursorExt from "~/foundation/Assets/svg/cursors/hover-cursor-ext.svg";

type ArticleBaseCardProps = {
	article: ItemReference<Article & ArticleExternal>;
}

const ArticleBaseCard: FC<ArticleBaseCardProps> = ({ article }) => {
	const [t] = useTranslation<GlobalDictionary>();
	const { sitecoreContext } = useSitecoreContext();
	const articleTheme = getArticleTheme(article);
	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

	const LinkComponent = article.fields?.externalLink ? Link : ChakraRouterLink;
	const linkProps = article.fields?.externalLink ? { href: article.fields.externalLink?.value?.href, target: article.fields.externalLink?.value?.target } : { to: article.url };

	return (
		<MotionBox initial="blur" whileHover="hover" animate="blur">
			<LinkComponent
				position="relative"
				variant="unstyled"
				display="block"
				w="100%"
				h="100%"
				cursor={`url(${article.fields?.externalLink ? cursorExt : cursor}) 20 20, pointer`}
				{...linkProps}
			>
				<Flex
					height={"100%"}
					flexDirection={["row", null, null, "column"]}
					alignItems={["flex-start", null, null, "stretch"]}
					bg={["none", null, null, articleTheme.article.bg]}
					borderTopStartRadius="var(--chakra-sizes-articleCardRoundedCorner)"
					borderBottomEndRadius="var(--chakra-sizes-articleCardRoundedCorner)"
				>
					{(article.fields.image?.value?.src || sitecoreContext.pageEditing) &&
						<>
							<AspectRatio ratio={16 / 9} w={["6.25rem", null, null, "100%"]} flexShrink="0" overflow="hidden" borderTopStartRadius="var(--chakra-sizes-articleCardRoundedCorner)">
								<Box>
									<MotionBox
										variants={articleListImageMotion}
										willChange="transform"
										h={["100%", null, null, "101%"]}
									>
										<sc.AdvanceImage
											fields={article.fields}
											itemId={article.id}
											fieldName="image"
											editable={false}
											srcSet={[imageSizes.defaultWide.sm, imageSizes.defaultWide.md]}
											sizes={`(min-width: ${breakpoints.sm}) 768px, 100vw`}
											sx={{
												objectFit: "cover",
												objectPosition: "center center",
												minWidth: "100%",
												minHeight: "100%",
											}}
										/>
									</MotionBox>
								</Box>
							</AspectRatio>

							{isLgBreakpointOrAbove && (
								<MotionBox display="inline-flex" variants={articleListArrowMotion} position="absolute" top="6" right="4">
									<IconArrowRight color={articleTheme.article.color} ms="0" />
								</MotionBox>
							)}
						</>
					}

					{((article.fields.listTeaserTitle?.value || article.fields.listTeaserText?.value) || sitecoreContext.pageEditing) &&
						<Box
							flexGrow="1"
							bg={articleTheme.article.bg}
							color={articleTheme.article.color}
							px={["4", null, null, "6"]}
							pt={["4", null, null, "6"]}
							pb={["4", null, null, "4"]}
							borderBottomEndRadius="var(--chakra-sizes-articleCardRoundedCorner)"
						>
							<Flex gap={[2, null, null, 4]} flexDirection={"row"} justifyContent={"space-between"} mb={["2", null, null, "4"]} textTransform={"lowercase"}>
								{(article.fields.topicTags && article.fields.topicTags?.length > 0) &&
									<ChakraText variant="tagLight" display="inline-block">
										{article.fields.topicTags?.map((tag: ItemReference<Tag>, index: number) => (
											<React.Fragment key={"tag" + index}>
												<sc.Text field={tag.fields.title} editable={false} />
												{(article.fields.topicTags?.length && index < article.fields.topicTags?.length - 1) &&
													<span>, </span>
												}
											</React.Fragment>
										))}
									</ChakraText>
								}

								{article.fields.externalLink?.value?.href && (
									<ChakraText
										variant="tagLight"
										display="inline-block"
										color={articleTheme.name === "Dark" ? "primary.white" : "primary.dark"}
										textAlign="end"
										border="1px solid"
										borderColor="primary.green"
										px="0.38rem"
										py={2}
										textTransform="lowercase"
										borderRadius="0.25rem"
										lineHeight={1}
									>
										{t("article.externalLabel")}
									</ChakraText>
								)}
							</Flex>

							{(article.fields.listTeaserTitle?.value || sitecoreContext.pageEditing) &&
								<Heading as="h3" size="headline4" mb={["2", null, null, "4"]}>
									<sc.Text field={article.fields.listTeaserTitle}/>
								</Heading>
							}

							{/*{(article.fields.listTeaserText?.value || sitecoreContext.pageEditing) &&*/}
							{/*	<ChakraText size="body">*/}
							{/*		<sc.Text field={article.fields.listTeaserText}/>*/}
							{/*	</ChakraText>*/}
							{/*}*/}
						</Box>
					}
				</Flex>
			</LinkComponent>
		</MotionBox>
	);
};

export default ArticleBaseCard;