import { type ComponentStyleConfig } from "@chakra-ui/react";
import { text as ThemeTextStyling } from "./Text";

export const Heading = (rtl = false): ComponentStyleConfig => ({
	// The styles all Headings have in common
	baseStyle: {
		fontFamily: 'heading',
		fontWeight: '300',
		lineHeight: 'base',
	},
	sizes: {
		...ThemeTextStyling(rtl).sizes
	},
});