import React from "react";
import { Box, type BoxProps, forwardRef } from "@chakra-ui/react";

export const ContentWrapper = forwardRef<BoxProps, "div">((props, ref) => (
	<Box
		ref={ref}
		className="content-wrapper"
		maxW={[null, null, null, null, "contentWrapperWidthXL"]}
		mx="auto"
		px={["4", "8", "16"]}
		py={["var(--chakra-sizes-modulePY__SM)", null, null, "var(--chakra-sizes-modulePY__LG)"]}
		{...props}
	/>
));