export const articleListImageMotion = {
	hidden: {
		opacity: 0
	},
	blur: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	},
	hover: {
		opacity: 1,
		scale: 1.025,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};

export const videoThumbnailMotion = {
	blur: {
		scale: 1,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	},
	hover: {
		scale: 1.015,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};

export const articleListArrowMotion = {
	blur: {
		opacity: 0,
		x: -16,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .2,
			},
		}
	},
	hover: {
		opacity: 1,
		x: 0,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};

export const quizCardContainerMotion = {
	blur: {
		opacity: 0,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .5,
			},
		}
	},
	default: {
		opacity: 1,
		transition: {
			duration: .5,
			ease: "easeOut",
			staggerChildren: 0.1,
			delayChildren: .3,
		}
	}
}

export const quizImageContainerMotion = {
	blur: {
		opacity: 0,
		y: 30,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .7,
			},
		}
	},
	default: {
		opacity: 1,
		y: 0,
		transition: {
			duration: .7,
			ease: "easeOut",
		}
	},
}

export const quizCardItemMotion = {
	blur: {
		opacity: 0,
		y: 30,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .6,
			},
		}
	},
	default: {
		opacity: 1,
		y: 0,
		transition: {
			duration: .6,
			ease: "easeOut",
		}
	},
}

export const newsletterContainerMotion = {
	blur: {
		opacity: 0,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .5,
			},
		}
	},
	default: {
		opacity: 1,
		transition: {
			duration: .5,
			ease: "easeOut",
			staggerChildren: 0.1,
			delayChildren: .3,
		}
	}
}

export const newsletterItemMotion = {
	blur: {
		opacity: 0,
		y: 30,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .6,
			},
		}
	},
	default: {
		opacity: 1,
		y: 0,
		transition: {
			duration: .6,
			ease: "easeOut",
		}
	},
}

interface PlayButtonMotionOptions {
	offset: number; // The responsive offset for the play button animation in px
}

export const playButtonOutlineMotion = ({ offset }: PlayButtonMotionOptions) => ({
	blur: {
		opacity: 0,
		transform: `translate(calc(-50% - ${offset}px), -50%)`
	},
	hover: {
		opacity: [0, 1, 0, 0, 1],
		transform: [
			`translate(calc(-50% - ${offset}px), -50%)`,
			"translate(-50%, -50%)",
			`translate(calc(-50% + ${offset}px), -50%)`,
			`translate(calc(-50% - ${offset}px), -50%)`,
			"translate(-50%, -50%)"
		],
		transition: {
			duration: 0.7,
			ease: "easeOut",
		},
	},
	after: {
		opacity: 1,
		x: 0
	}
});

export const playButtonMotion = ({ offset }: PlayButtonMotionOptions) => ({
	blur: {
		opacity: 1,
		transform: "translate(-50%, -50%)"
	},
	hover: {
		opacity: [1, 0, 0, 1, 0],
		transform: [
			"translate(-50%, -50%)",
			`translate(calc(-50% + ${offset}px), -50%)`,
			`translate(calc(-50% - ${offset}px), -50%)`,
			"translate(-50%, -50%)",
			`translate(calc(-50% + ${offset}px), -50%)`
		],
		transition: {
			duration: 0.7,
			ease: "easeOut",
		},
	},
	after: {
		opacity: 1,
		x: 0
	}
});

export const headerMotionVariants = {
	container: {
		open: {
			transform: "translateY(0%)",
		},
		closed: {
			transform: "translateY(-100%)",
		},
	},
	logo: {
		initial: {
			transform: "translateY(0%)",
		},
		first: {
			transform: "translateY(0%)",
		},
		second: {
			transform: "translateY(0%)",
		},
	}
}

export const motionVariantsHomePage = (isXL: boolean) => ({
	container: {
		default: {
			transform: "translateY(0%)",
		},
		initial: {
			transform: "translateY(-150%)",
		},
		open: {
			transform: "translateY(0%)",
		},
		closed: {
			transform: "translateY(-100%)",
		},
		first: {
			transform: "translateY(0%)",
			paddingTop: isXL ? "var(--chakra-sizes-headerHLGExpandedTop)" : 0,
		},
		second: {
			transform: "translateY(0%)",
			paddingTop: "0rem",
		},
	},
	logo: {
		initial: {
			transform: isXL ? "translateY(-200%)" : "translateY(0%)",
		},
		first: {
			transform: isXL ? "translateY(-200%)" : "translateY(0%)",
		},
		second: {
			transform: "translateY(0%)",
		},
	},
	svg: {
		initial: {
			width: isXL ? "var(--chakra-sizes-headerExpandedLogoWidth)" : "var(--chakra-sizes-headerExpandedLogoWidthSM)",
			height: "auto",
		},
		first: {
			width: isXL ? "var(--chakra-sizes-headerExpandedLogoWidth)" : "var(--chakra-sizes-headerExpandedLogoWidthSM)",
			height: "auto",
		},
		second: {
			width: isXL ? "var(--chakra-sizes-headerExpandedLogoWidthLG)" : "var(--chakra-sizes-headerExpandedLogoWidthSM)",
			height: isXL ? "var(--chakra-sizes-headerExpandedLogoHeightLG)" : "auto",
		}
	},
	logoSecondary: {
		initial: {
			opacity: "100%",
			marginLeft: "1.19rem"
		},
		first: {
			opacity: "100%",
			marginLeft: "1.19rem"
		},
		second: {
			opacity: "0%",
			height: "0",
			width: "0",
			marginLeft: "0rem"
		}
	}
});

export const fadeUp = {
	initial: {
		y: "25px",
		opacity: "0%",
	},
	active: {
		y: "0",
		opacity: "100%",
	}
}

export const worldTransitions = {
	default: {
		ease: "easeOut",//[.35, 1, .45, 1],
		duration: .3,
	},
	easeOutSlow: {
		ease: "easeOut",//[.35, 1, .45, 1],
		duration: .8,
	}
}

export const resultsContainer = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			duration: .5,
			staggerChildren: 0.1
		}
	}
}

export const fadeIn = {
	hidden: { opacity: 0 },
	show: { opacity: 1 }
}
