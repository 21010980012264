import { colors } from "../variables/colors";
import { baseColorTheme } from "./base-color-theme";

export const getExtraGreenColorTheme = () => ({
	name: "Extra green",
	primaryColor: "var(--chakra-colors-TechPrimary-extraGreen)",
	primaryGradient: colors.TechPrimary.greenExtra.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	secondaryColor: "var(--chakra-colors-TechPrimary-white)",
	linearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),linear-gradient(63.93deg, rgba(109, 193, 96, 0) -3.15%, #6DC160 37.44%)",
	reversedLinearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),linear-gradient(-63.93deg, rgba(109, 193, 96, 0) -3.15%, #6DC160 37.44%)",
	...baseColorTheme
});

export type ExtraGreenColorTheme = ReturnType<typeof getExtraGreenColorTheme>;