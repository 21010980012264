import React, { createContext, type FC, useContext, useState } from "react";

type ContainerContextType = {
	divider?: 'both' | 'top' | 'bottom' | 'none';
	themed?: boolean;
}

const defaultContainerContext: ContainerContextType = {
	divider: 'none',
	themed: false,
}

export const ContainerContext = createContext(defaultContainerContext);

type ContainerContextProps = {
	children: React.ReactNode;
}

export const ColorThemeProvider: FC<ContainerContextProps> = ({ children }) => {
	const [constainerState] = useState<ContainerContextType>(defaultContainerContext);

	return (
		<ContainerContext.Provider value={constainerState}>
			{children}
		</ContainerContext.Provider>
	);
};

export const useContainerContext = () => {
	const context = useContext(ContainerContext);
	if (!context) {
		throw new Error("useContainerContext must be used within a ContainerContextProvider");
	}
	return context;
};
