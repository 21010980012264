import React from "react";
import { type PageThemeBase } from "~/foundation/Theme/generated-types";
import { type ColorTheme } from "./color-themes/default-color-theme";
import { type ColorThemeId, themes } from "./themes";
import { ColorThemeProvider } from "./ColorThemeContext";
import { useSitecoreContext } from "~/foundation/Jss";
import { type ColorThemeRenderingParameter } from "../../generated-types";

export type WithThemeProps = {
	params: ColorThemeRenderingParameter;
}

export function getColorTheme(themeId: ColorThemeId | undefined): ColorTheme | null {
	let colorTheme: ColorTheme | null = null;
	if (themeId) {
		const colorThemeFn = themes[themeId];
		if (colorThemeFn && typeof colorThemeFn === "function") {
			colorTheme = colorThemeFn();
		}
	}

	return colorTheme;
}

export function themable() {
	return function themable<P extends WithThemeProps>(InputComponent: React.FC<P>) {

		const Themable = (props: P) => {
			const colorTheme = getColorTheme(props.params.backgroundTheme as ColorThemeId);
			if (colorTheme) {
				return (
					<ColorThemeProvider colorTheme={colorTheme}>
						<InputComponent {...props} />
					</ColorThemeProvider>
				);
			}

			return <InputComponent {...props} />
		}

		Themable.displayName = "Themable";

		return Themable;
	};
}

export function usePageTheme() {
	return function usePageTheme<P extends object>(InputComponent: React.FC<P>) {

		const UsePageTheme = (props: P) => {
			const { sitecoreContext } = useSitecoreContext<PageThemeBase>();

			const colorTheme = getColorTheme(sitecoreContext.route?.fields?.pageColorTheme?.value as ColorThemeId);
			if (colorTheme) {
				return (
					<ColorThemeProvider colorTheme={colorTheme}>
						<InputComponent {...props} />
					</ColorThemeProvider>
				);
			}

			return <InputComponent {...props} />
		}

		UsePageTheme.displayName = "UsePageTheme";

		return UsePageTheme;
	};
}

