export type ThemeType = {
	name: string;
	primaryColor: string;
	secondaryColor: string;
	linearGradient: string;
	reversedLinearGradient: string;
	dark: string;
	light: string;
	primaryGradient: string;
}

export const baseColorTheme = {
	light: "var(--chakra-colors-TechBase-light)",
	dark: "var(--chakra-colors-TechBase-dark)",
};
