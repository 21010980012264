import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { selectAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({

});

const newsletter = definePartsStyle({
	field: {
		cursor: "pointer",
		px: 0,
		pb: 3,
		pt: 6,
		borderRadius: 0,
		height: "full",
		color: "primary.greyDark",
		borderBottom: 1,
		borderStyle: "dashed",
		borderColor: "primary.dark",
		_placeholderShown: {
			borderStyle: "dashed",
			borderColor: "primary.dark"
		},
		_hover: {
			color: "primary.dark",
			borderStyle: "solid",
		},
		_focus: {
			color: "primary.dark",
			borderStyle: "solid"
		},
		_autofill: {
			textFillColor: "primary.dark",
			boxShadow: "0 0 0px 1000px #FFF inset",
			transition: "background-color 5000s ease-in-out 0s",
		},
	},
	icon: {
		color: "primary.greyDark",
		right: 0,
	}
})

export const Select = defineMultiStyleConfig({ baseStyle, variants: { newsletter } })
