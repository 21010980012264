import React, { type FC, useContext, useState, type ReactNode } from "react";

type NavigationContextType = {
	isSearchOpen: boolean;
	isMobileMenuOpen: boolean;
	toggleMobileMenu: (force?: boolean) => void;
	toggleSearch: (force?: boolean) => void;
	activeSubmenus?: { [key: string]: boolean };
	setActiveSubmenus?: (submenus: { [key: string]: boolean }) => void;
	setIsMobileMenuOpen?: (isOpen: boolean) => void;
};

type NavigationProviderProps = {
	children: ReactNode;
};

const NavigationContext = React.createContext<NavigationContextType | undefined>(undefined);

export const useNavigationContext = () => {
	const context = useContext(NavigationContext);
	if (!context) {
		throw new Error("useNavigationContext must be used within a NavigationProvider");
	}
	return context;
};

export const NavigationProvider: FC<NavigationProviderProps> = ({ children }) => {
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [activeSubmenus, setActiveSubmenus] = useState<{ [key: string]: boolean }>({});

	const toggleMobileMenu = (force = false) => {
		setIsMobileMenuOpen((prev) => {
			if (force || (!prev && isSearchOpen)) {
				setIsSearchOpen(false); // Close search if it's open
			}
			return !prev;
		});
	};

	const toggleSearch = (force = false) => {
		setIsSearchOpen((prev) => {
			if (force || (!prev && isMobileMenuOpen)) {
				setIsMobileMenuOpen(false); // Close mobile menu if it's open
			}
			return !prev;
		});
	};

	const value: NavigationContextType = {
		isMobileMenuOpen,
		isSearchOpen,
		toggleMobileMenu,
		toggleSearch,
		activeSubmenus,
		setActiveSubmenus,
		setIsMobileMenuOpen,
	};

	return (
		<NavigationContext.Provider value={value}>
			{children}
		</NavigationContext.Provider>
	);
};