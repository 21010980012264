import { Box, Text, type TextProps } from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { animationOptions } from "../Theme/variables/animation";

const MotionText = motion(Text)

type PresenceTextProps = TextProps & {
	once?: boolean
}

export const PresenceText = ({ children, once = true, ...props }: PresenceTextProps) => {
	const inViewRef = useRef<HTMLDivElement>(null)
	const isInView = useInView(inViewRef, { once })

	return (
		<Box
			ref={inViewRef}
			overflow={"hidden"}
		>
			<MotionText
				initial={{ opacity: 0, y: "15%" }} 
				animate={isInView ? { opacity: 1, y: 0 } : {}} 
				transition={animationOptions({})}
				style={{ willChange: "transform" }}
				{...props}>
				{children}
			</MotionText>
		</Box>
	)
}