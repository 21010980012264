import { Icon, forwardRef, type IconProps } from "@chakra-ui/react";

export interface BundleIconProps extends IconProps {
	name: "facebook" | "instagram" | "x" | "linkedin" | "button-arrow" | "chevron" | "close" | "download-arrow" | "envelop" | "play" | "add-image" | "calendar" | "clock" | "map-pin";
	size?: string | (string | number | null)[] | number;
	isRtl?: boolean;
}

const BundleIcon = forwardRef<BundleIconProps, "svg">(
	({ name, isRtl, ...props }, ref) => {
		return (
			<Icon
				{...props}
				ref={ref}
				aria-hidden="true"
				transform={isRtl ? "scaleX(-1)" : ""}
			>
				<use xlinkHref={`#satc_icon_${name}`} />
			</Icon>
		);
	}
);
export default BundleIcon;
