const primary = {
	dark: "#1A1A1A",
	greyDark: "#646464",
	greyMedium: "#999B9D",
	grey: "#D2D6DA",
	greyLight: "#F3F3F3",
	greyUltraLight: "#FBF4F4",
	white: "#FFF",
	wrong: "#FF9090",
	yellow: "#FFF96E",
	red: "#C51315",
	blue: "#1480DB",
	darkBlue: "#1368b0",
	green: "#00AD4A",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getColors = (rtl: boolean) => ({
	primary,
});

type ColorsObj = ReturnType<typeof getColors>;
export type Colors = (ColorsObj[keyof ColorsObj]) | "currentColor";