import { Box, Heading, type HeadingProps } from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { animationOptions } from "../Theme/variables/animation";

const MotionText = motion(Heading)

type PresenceHeadingProps = HeadingProps & {
	once?: boolean
	duration?: number
}

export const PresenceHeading = ({ children, duration, once = true, ...props }: PresenceHeadingProps) => {
	const inViewRef = useRef<HTMLDivElement>(null)
	const isInView = useInView(inViewRef, { once })

	return (
		<Box
			ref={inViewRef}
			overflow={"hidden"}
		>
			<MotionText
				initial={{ opacity: 0, y: "15%" }} 
				animate={isInView ? { opacity: 1, y: 0 } : {}} 
				transition={animationOptions({ duration })}
				style={{ willChange: "transform" }}
				{...props}
			>
				{children}
			</MotionText>
		</Box>
	)
    
}