export const keyFrames = () => ({
	keyFrames: {
		"@keyframes skeleton": {
			from: {
				borderColor: "var(--chakra-colors-primary-brightGrey)",
				background: "var(--chakra-colors-primary-brightGrey)",
			},
			to: {
				borderColor: "var(--chakra-colors-primary-neutralGrey)",
				background: "var(--chakra-colors-primary-neutralGrey)",
			}
		},
	}
});