import { type ComponentStyleConfig } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const text = (rtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: 'heading',
		fontWeight: '300',
		lineHeight: 'base',
	},
	sizes: {
		headlineLight: {
			fontSize: ["1.75rem", null, null, "2.5rem"],//["28px", null, null, "40px"],
			fontWeight: "300",
			lineHeight: "1",
			letterSpacing: ["-0.035rem", null, null, "-0.05rem"],
		},
		headline1: {
			fontSize: ["2.25rem", null, null, "3.125rem"],//["36px", null, null, "50px"],
			fontWeight: '750',
			lineHeight: ['1.28', null, null, "1.2"],
			letterSpacing: "-0.03em",
		},
		headline2: {
			fontSize: ["1.625rem", null, null, "2.125rem"],//["26px", null, null, "34px"],
			fontWeight: '750',
			lineHeight: ['1.29', null, null, "1.31"],
			letterSpacing: "-0.03em",
		},
		headline2Light: {
			fontSize: ["1.625rem", null, null, "2.125rem"],//["26px", null, null, "34px"],
			fontWeight: '300',
			lineHeight: ['1.29', null, null, "1.31"],
			letterSpacing: "-0.01em",
		},
		headline3: {
			fontSize: ["1.3125rem", null, null, "1.75rem"],//["21px", null, null, "28px"],
			fontWeight: '750',
			lineHeight: ['1.28', null, null, "1.32"],
			letterSpacing: "-0.03em",
		},
		headline3Light: {
			fontSize: ["1.3125rem", null, null, "1.75rem"],//["21px", null, null, "28px"],
			fontWeight: '300',
			lineHeight: ['1.28', null, null, "1.3"],
			letterSpacing: "-0.01em",
		},
		headline4: {
			fontSize: ["1.0625rem", null, null, "1.25rem"],//["17px", null, null, "20px"],
			fontWeight: '750',
			lineHeight: ['1.41', null, null, "1.35"],
			letterSpacing: "-0.03em",
		},
		headline4Light: {
			fontSize: ["1.0625rem", null, null, "1.25rem"],//["17px", null, null, "20px"],
			fontWeight: '300',
			lineHeight: ['1.41', null, null, "1.35"],
			letterSpacing: "-0.01em",
		},
		headline5: {
			fontWeight: "750",
			fontSize: [".75rem", null, null, ".8125rem"], //["12px", null, null, "13px"],
			lineHeight: ["1.25", null, null, "1.38"],
			letterSpacing: ["-0.02em", null, null, "-0.03em"],
		},
		body: {
			fontSize: ["1.0625rem", null, null, "1.125rem"], //["17px", null, null, "18px"],
			fontWeight: "300",
			lineHeight: ["1.41", null, null, "1.5"],
			letterSpacing: "-0.01em",
		},
		bodySmall: {
			fontSize: [".9375rem", null, null, "1rem"], //["15px", null, null, "16px"],
			fontWeight: "300",
			lineHeight: ["1.47", null, null, "1.5"],
			letterSpacing: "-0.01em",
		},
	},
	variants: {
		'tagBold': {
			fontWeight: "750",
			fontSize: [".75rem", null, null, ".8125rem"], //["12px", null, null, "13px"],
			lineHeight: ["1.25", null, null, "1.38"],
			letterSpacing: ["-0.02em", null, null, "-0.03em"],
		},
		'tagLight': {
			fontWeight: "300",
			fontSize: [".75rem", null, null, ".8125rem"], //["12px", null, null, "13px"],
			lineHeight: ["1.25", null, null, "1.38"],
			letterSpacing: ["-0.02em", null, null, "-0.02em"],
		},
		'tag': {
			fontWeight: "400",
			fontSize: [".75rem", null, null, ".8125rem"], //["12px", null, null, "13px"],
			lineHeight: ["1.25", null, null, "1.38"],
			letterSpacing: ["-0.02em", null, null, "-0.02em"],
		},
		"breadcrumb": {
			fontWeight: "300",
			fontSize: ".75rem",
			lineHeight: "2",
			letterSpacing: "-0.02em",
		}
	},
});
