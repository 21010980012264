import React, { type FC, useEffect, useRef } from "react";
import { sc } from "../../_Base/Jss";
import { type TextField, useSitecoreContext } from "~/foundation/Jss";
import { Box, Button, Heading, VisuallyHidden, Text as ChakraText, useMediaQuery } from "@chakra-ui/react";
import { type ISearchResultItemModel } from "~/feature/Search/generated-types";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import StoryListItem, { AspectRatioOption } from "../../Story/StoryList/StoryListItem";
import { ButtonLoader } from "../../_Base/Components/Loading/Loader";
import { useTranslation } from "~/foundation/Dictionary";
import { type GlobalDictionary } from "../../dictionary";
import { fadeIn, resultsContainer, worldTransitions } from "../../_Base/Theme/styles/motions";
import SkeletonResultItem from "./SkeletonResultItem";
import { breakpoints } from "../../_Base/Theme/variables/breakpoints";

type ResultsListProps = {
	headline?: TextField | string | undefined;
	loadMore: boolean;
	results?: Array<ISearchResultItemModel>;
	isLoading: boolean;
	page: number;
	pageSize: number | undefined;
	totalResultCount: number;
	setPage?: React.Dispatch<React.SetStateAction<number>>;
	uselayout?: boolean;
	loadMoreSuffix?: string;
	skeletonLoad?: boolean;
	filterType?: string | undefined;
}

const ResultsList: FC<ResultsListProps> = ({
	headline,
	loadMore,
	results,
	isLoading,
	page,
	pageSize,
	totalResultCount,
	setPage = () => { },
	uselayout,
	loadMoreSuffix,
	skeletonLoad,
	filterType = undefined
}) => {
	const { sitecoreContext } = useSitecoreContext();
	const [t] = useTranslation<GlobalDictionary>();
	const [isLgBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`);

	const storyList = useRef<HTMLUListElement>(null);
	const statusMessage = useRef<HTMLSpanElement>(null);
	const allContentLoaded = totalResultCount <= ((pageSize || 0) * page);

	const updatePage = () => {
		if (totalResultCount) {
			if (totalResultCount > ((pageSize || 0) * page)) {
				setPage(page => page + 1)
			}
		}
	}

	// Focus on newest item after loading is done and results are updated
	useEffect(() => {
		if (storyList.current && loadMore && page > 1 && !isLoading) {
			const focusIndex = (page - 1) * (pageSize || 0);
			const firstAddedItem = storyList.current.childNodes[focusIndex]?.childNodes[0] as HTMLAnchorElement;
			firstAddedItem?.focus();
		}
	}, [results, isLoading]);

	useEffect(() => {
		if (statusMessage.current) {
			statusMessage.current.textContent =
				isLoading ?
					(t("resultsList.loadingContent") || "Loading content")
					:
					(t("resultsList.contentLoaded") || "Done loading content");
		}
	}, [isLoading]);

	const getGridLayout = (index: number) => {
		if (!uselayout) {
			return ["span 1"];
		}

		let gridColsLg = 3; // Default cols, lg bp and above
		if (filterType !== "review" && filterType !== "issue" && !isLgBreakpointOrAbove) {
			gridColsLg = 2;
		}
		const row = Math.floor(index / gridColsLg);

		if (filterType === "review" || filterType === "issue") {
			if (isLgBreakpointOrAbove) {
				return ["span 3"];
			} else if (isMdBreakpointOrAbove) {
				return ["span 4"];
			} else {
				return ["span 6"];
			}
		}

		let layouts = [
			["span 4", "span 6"],
			["span 6", "span 4"],
			["span 3", "span 7"],
			["span 4", "span 6"],
			["span 6", "span 4"],
			["span 3", "span 7"]
		];

		if (isLgBreakpointOrAbove) {
			layouts = [
				["span 4", "span 3", "span 3"],
				["span 3", "span 3", "span 4"]
			];
		}

		return layouts[row % layouts.length];
	};

	const getTemplateGrid = (filterType?: string) => {
		if (!uselayout) {
			return "1fr 1fr 1fr";
		}
		if (filterType === "review" || filterType === "issue") {
			return "repeat(12, 1fr)";
		}
		return "repeat(10, 1fr)";
	};

	const renderSkeletons = () => {
		let defaultSkeletonAmount = 3;

		if (filterType !== "review" && filterType !== "issue" && !isLgBreakpointOrAbove) {
			defaultSkeletonAmount = 2;
		}

		if (filterType === "review" || filterType === "issue") {
			if (isLgBreakpointOrAbove) {
				defaultSkeletonAmount = 4;
			} else if (isMdBreakpointOrAbove) {
				defaultSkeletonAmount = 3;
			} else {
				defaultSkeletonAmount = 2;
			}
		}

		const skeletons = [];
		for (let i = 0; i < defaultSkeletonAmount; i++) {
			const layout = getGridLayout(i);
			const gridColumn = layout ? layout[i % layout.length] : "auto";
			skeletons.push(
				<SkeletonResultItem key={i} gridColumn={gridColumn.toString()} />
			);
		}
		return skeletons;
	};

	return (
		<Box textAlign="center" mb={10}>
			{(headline || sitecoreContext.pageEditing) &&
				<Heading
					as="h2"
					size="desktopHeadline7"
					width="100%"
					textAlign="start"
					borderColor="currentcolor"
					borderBottomWidth="1px"
					borderStyle="solid"
					py="2"
					mb={["var(--chakra-sizes-modulePY__SM)", null, null, "var(--chakra-sizes-modulePY__LG)"]}
				>
					{typeof headline === "string" ? (
						headline
					) : (
						<sc.Text field={headline} />
					)}
				</Heading>
			}

			{loadMore &&
				<VisuallyHidden
					ref={statusMessage}
					as="span"
					role="status"
					aria-live="polite"
					aria-atomic="true"
				/>
			}

			{results && results?.length > 0 ? (
				<MotionBox
					ref={storyList}
					as="ul"
					aria-busy={isLoading}
					flexDir="column"
					display={["flex", "grid", null, null]}
					gridTemplateColumns={
						[
							"auto",
							getTemplateGrid(filterType || undefined),
							null,
							null
						]
					}
					gridColumnGap="8"
					gridRowGap={["12", null, "16", null]}
					textAlign="start"
					variants={resultsContainer}
					initial="hidden"
					animate="show"
					className="lazyLoadList"
				>
					{results.map((listItem, i) => {
						// if (!listItem.url) return null;
						const layout = getGridLayout(i); // Get the layout for the row
						const itemIndexWithinRow = i % layout.length; // Get the item index within that row
						const gridColumn = layout[itemIndexWithinRow] || "auto"; // Get the grid column based on the item index within the row

						let aspectRatio = AspectRatioOption.default;
						if (listItem.section === "Reviews") {
							aspectRatio = AspectRatioOption.auto;
						} else if (listItem.contentType === "Issue") {
							aspectRatio = AspectRatioOption.cover;
						}

						// Render skeleton if loading more results
						if (isLoading && skeletonLoad) {
							return (
								<SkeletonResultItem
									key={i}
									gridColumn={gridColumn.toString()}
									aspectRatio={aspectRatio}
								/>
							);
						}

						return (
							<StoryListItem
								key={i}
								gridColumn={gridColumn.toString()}
								aspectRatio={aspectRatio}
								isExternal={listItem.contentType === "Issue"}
								{...listItem}
							/>
						);
					})}
				</MotionBox>
			) : (
				(!isLoading ? (
					<MotionBox
						variants={fadeIn}
						display="flex"
						initial="hidden"
						animate="show"
						justifyContent="center"
						alignItems="center"
						mb={12}
						textAlign={["center", null, "start", null]}
						transition={worldTransitions.easeOutSlow}
					>
						<ChakraText as="h3" size="desktopHeadline4">
							{t("general.search.noResultsLabel")}
						</ChakraText>
					</MotionBox>
				) : (
					<>
						{skeletonLoad && (
							<MotionBox
								ref={storyList}
								as="ul"
								flexDir="column"
								display={["flex", "grid", null, null]}
								gridTemplateColumns={
									[
										"auto",
										getTemplateGrid(filterType || undefined),
										null,
										null
									]
								}
								gridColumnGap="8"
								gridRowGap={["12", null, "16", null]}
								variants={fadeIn}
								initial="hidden"
								animate="show"
								transition={worldTransitions.easeOutSlow}
							>
								{renderSkeletons()}
							</MotionBox>
						)}
					</>
				))
			)}

			{loadMore && totalResultCount > 0 &&
				<Button
					aria-controls={storyList.current?.id}
					aria-disabled={allContentLoaded || isLoading}
					tabIndex={allContentLoaded ? -1 : 0}
					size="lg"
					variant="primaryOutline"
					textTransform="none"
					mt={10}
					isLoading={isLoading}
					spinner={<ButtonLoader />}
					onClick={updatePage}
				>
					{allContentLoaded ? t("general.search.allContentLoaded") : t("general.search.loadMoreLabel")}

					{loadMoreSuffix && !allContentLoaded && (
						<>
							{" "}
							{loadMoreSuffix}
						</>
					)}
				</Button>
			}
		</Box>
	);
};

export default ResultsList;