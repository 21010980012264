/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const getButtonVariants = (isRtl: boolean) => ({
	'unstyled': {
		display: "inline-flex",
		h: "fit-content",
		w: "fit-content",
		textTransform: "lowercase",
		py: 0,
		px: 0,
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
		minWidth: "fit-content",
	},
	'secondaryGrey': {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 4,
		h: "fit-content",
		w: "fit-content",
		backgroundColor: "primary.grey",
		textColor: "primary.dark",
		textTransform: "lowercase",
		fontWeight: "light",
		py: 3,
		px: 6,
		fontFamily: 'body',
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
	},
	'primaryCorner': {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 4,
		h: "fit-content",
		w: "fit-content",
		minWidth: "fit-content",
		backgroundColor: "primary.dark",
		textColor: "primary.white",
		textTransform: "lowercase",
		fontWeight: "light",
		py: 3,
		pl: 6,
		pr: 10,
		fontFamily: 'body',
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
		borderRadius: "0.5rem 0rem 0rem 0.5rem",
		clipPath: "polygon(100% 0%, 0% 0%,0% 100%, calc(100% - var(--chakra-sizes-articleCornerSMWidth)) 100%, calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .95) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .002), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .91) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0078), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .86) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0178), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .82) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .031), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .78) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0485), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .74) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .069), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .705) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0927), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .672) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .1196), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .641) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .1496), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .614) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .1821), 100% calc(100% - var(--chakra-sizes-articleCornerSMHeight)))",
		'&:hover, &:active': {
			backgroundColor: "primary.white",
			textColor: "primary.dark",
		},
	},
	'primaryCornerLight': {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 4,
		h: "fit-content",
		w: "fit-content",
		minWidth: "fit-content",
		backgroundColor: "primary.white",
		textColor: "primary.dark",
		textTransform: "lowercase",
		fontWeight: "light",
		py: 3,
		pl: 6,
		pr: 10,
		fontFamily: 'body',
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
		borderRadius: "0.5rem 0rem 0rem 0.5rem",
		clipPath: "polygon(100% 0%, 0% 0%,0% 100%, calc(100% - var(--chakra-sizes-articleCornerSMWidth)) 100%, calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .95) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .002), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .91) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0078), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .86) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0178), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .82) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .031), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .78) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0485), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .74) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .069), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .705) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .0927), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .672) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .1196), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .641) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .1496), calc(100% - var(--chakra-sizes-articleCornerSMWidth) * .614) calc(100% - var(--chakra-sizes-articleCornerSMHeight) * .1821), 100% calc(100% - var(--chakra-sizes-articleCornerSMHeight)))",
		'&:hover, &:active': {
			backgroundColor: "primary.dark",
			textColor: "primary.white",
		},
	},
	'primaryCornerNewsletter': {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 4,
		h: "fit-content",
		w: "fit-content",
		minWidth: "fit-content",
		backgroundColor: "primary.blue",
		color: "primary.white",
		textTransform: "lowercase",
		fontWeight: "light",
		py: 3,
		pr: 6,
		pl: 10,
		fontFamily: 'body',
		transition: ".6s cubic-bezier(.35,1,.45,1) background",
		borderTopRightRadius: "0.5rem",
		borderBottomRightRadius: "0.5rem",
		clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, calc(var(--chakra-sizes-articleCornerSMWidth)) 0%, calc(var(--chakra-sizes-articleCornerSMWidth) * .95) calc(var(--chakra-sizes-articleCornerSMHeight) * .002), calc(var(--chakra-sizes-articleCornerSMWidth) * .91) calc(var(--chakra-sizes-articleCornerSMHeight) * .0078), calc(var(--chakra-sizes-articleCornerSMWidth) * .86) calc(var(--chakra-sizes-articleCornerSMHeight) * .0178), calc(var(--chakra-sizes-articleCornerSMWidth) * .82) calc(var(--chakra-sizes-articleCornerSMHeight) * .031), calc(var(--chakra-sizes-articleCornerSMWidth) * .78) calc(var(--chakra-sizes-articleCornerSMHeight) * .0485), calc(var(--chakra-sizes-articleCornerSMWidth) * .74) calc(var(--chakra-sizes-articleCornerSMHeight) * .069), calc(var(--chakra-sizes-articleCornerSMWidth) * .705) calc(var(--chakra-sizes-articleCornerSMHeight) * .0927), calc(var(--chakra-sizes-articleCornerSMWidth) * .672) calc(var(--chakra-sizes-articleCornerSMHeight) * .1196), calc(var(--chakra-sizes-articleCornerSMWidth) * .641) calc(var(--chakra-sizes-articleCornerSMHeight) * .1496), calc(var(--chakra-sizes-articleCornerSMWidth) * .614) calc(var(--chakra-sizes-articleCornerSMHeight) * .1821), 0% calc(var(--chakra-sizes-articleCornerSMHeight)))",
		'&:hover, &:active': {
			backgroundColor: "primary.darkBlue",
		},
	},
});

export type ButtonVariant = keyof (ReturnType<typeof getButtonVariants>);

export const Button = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: 'body',
		fontWeight: '300',
		h: "fit-content",
		w: "fit-content",
	},
	variants: getButtonVariants(isRtl),
});