/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type FC } from "react";
import { Link as ChakraLink, type LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import {
	Link as RouterLink,
	type LinkProps as RouterLinkProps,
} from "react-router-dom";
import { type LinkSize, type LinkVariant } from "../../Theme/components/Link";
import { pushDataLayer } from "~/foundation/Tracking/GoogleTagManager";

export type LinkProps = ChakraLinkProps & {
	to?: RouterLinkProps["to"];
	size?: LinkSize;
	variant?: LinkVariant;
	ariaLabel?: string;
	linkLabel?: string;
	target?: string;
	className?: string;
	rel?: string;
	children?: RouterLinkProps["children"];
	dataLayer?: () => GTMDataLayer;
};

export const Link: FC<LinkProps> = ({
	to,
	size,
	variant,
	ariaLabel,
	target,
	className,
	rel,
	children,
	dataLayer,
	...rest
}) => {
	const ref = React.createRef<HTMLAnchorElement>();

	return (
		<ChakraLink
			as={to ? RouterLink : "span"}
			to={to}
			size={size}
			variant={variant}
			aria-label={ariaLabel}
			target={target}
			className={`${className} ${variant}`}
			rel={rel}
			ref={ref}
			{...(dataLayer && {
				onClick: () => {
					pushDataLayer(dataLayer);
				},
			})}
			{...rest}
		>
			{children &&
				<span>{children}</span>
			}
		</ChakraLink>
	);
};
