import { Box, Link } from "@chakra-ui/react";
import { type BreadcrumbItem } from "~/foundation/SitecoreContent/generated-types";
import { Breadcrumb } from "./ui/breadcrumb";
import { colors } from "../theme/variables/colors";
import { type GlobalDictionary } from "../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";

const Breadcrumbs = ({ items }: { items: BreadcrumbItem[] }) => {
	const [t] = useTranslation<GlobalDictionary>();
	const labels: Record<string, string> = {
		home: t("breadcrumb.home"),
	}
	return (
		<nav aria-label="Breadcrumb">
			<Box
				as="ol"
				sx={{
					listStyle: "none",
					display: "flex",
				}}
			>
				<li>
					<Link href={"/"}>
						<Breadcrumb>
							{labels.home}
						</Breadcrumb>

						<Box sx={{
							color: colors.TechBase.grey,
							padding: "0 .5rem"
						}}
						>/</Box>
					</Link>
				</li>
				{items.map((item, index) => {
					return (
						<li key={index}>
							<Link href={item.url}>
								<Breadcrumb isActive={(index + 1) === items.length}>
									{item.title || "Untitled"}
								</Breadcrumb>
								{items.length > (index + 1) ?
									<Box sx={{
										color: colors.TechBase.grey,
										padding: "0 .5rem"
									}}
									>/</Box> : null}
							</Link>
						</li>
					);
				})}
			</Box>
		</nav>
	);
};

export { Breadcrumbs };