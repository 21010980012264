import React, { type FC } from "react";
import { sc } from "../../../_Base/Jss";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { type GenericHeaderBase } from "~/foundation/SitecoreContent/generated-types";
import { IconTeq } from "../Icon";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

type GenericHeaderProps = GenericHeaderBase & { hideTeq?: boolean };

const GenericHeader: FC<GenericHeaderProps> = ({ headline, link, trumpet, hideTeq, ...rest }) => {
	const { sitecoreContext } = useSitecoreContext();

	return (
		<>
			<Flex
				justifyContent="space-between"
				gap="2"
				mb={["4", null, null, "8"]}
				{...rest}
			>
				{(headline?.value || sitecoreContext.pageEditing) && (
					<Box display="flex" gap={["2px", null, null, "0"]}>
						<Heading as="h2" size="headline3Light" textTransform="lowercase" display="inline-block">
							{headline?.value}
							{!hideTeq &&
								<IconTeq ml={["1px", null, null, "1px", null]} mt={["1.5px", null, null, "3px"]} />
							}
						</Heading>

						{trumpet?.value && (
							<Text size="bodySmall">
								{trumpet.value?.toLowerCase()}
							</Text>
						)}
					</Box>
				)}

				{(link?.value.href || sitecoreContext.pageEditing) &&
					<Box alignSelf={["start", null, "end", null]} mb={["0", null, null, "1"]} textTransform="lowercase">
						<sc.Link field={link} variant="primary" />
					</Box>
				}
			</Flex>
		</>
	);
}

export default GenericHeader;