import { type ItemReference } from "~/foundation/Jss";
import { type ColorThemeId, themes } from "../Theme";
import { type PageThemeBase } from "~/foundation/Theme/generated-types";

export const getColorTheme = (colorThemeId: ColorThemeId | undefined) => {
	return themes[colorThemeId || "1"](false);
}

export const getArticleTheme = (article: ItemReference<PageThemeBase>) => {
	return getColorTheme(article.fields.pageColorTheme?.value as ColorThemeId);
}