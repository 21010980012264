/* eslint-disable @typescript-eslint/no-unused-vars */
import { extendBaseTheme } from "@chakra-ui/react";
import { type DeepPartial } from "utility-types";
import { colors } from "./variables/colors";
import { breakpoints } from "./variables/breakpoints";
import { fonts } from "./variables/fonts";
import { sizes } from "./variables/sizes";
import { space } from "./variables/space";
import { getGlobalStyling } from "./styles/global";
import { theme as chakraTheme } from "@chakra-ui/theme";
import { Heading } from "./components/Heading";
import { Button } from "./components/Button";
import { Link } from "./components/Link";
import { Text } from "./components/Text";
import { Accordion } from "./components/Accordion";
import deepmerge from "deepmerge";
import { Input } from "./components/Input";
import { Checkbox } from "./components/Checkbox";
import { Select } from "./components/Select";

const {
	Button: ChakraButton,
	Link: ChakraLink,
	Heading: ChakraHeading,
	Accordion: ChakraAccordion,
	Input: ChakraInput,
	Checkbox: ChakraCheckbox,
	// 	Menu: ChakraMenu,
	// 	Popover: ChakraPopover,
	// 	Radio: ChakraRadio,
	Select: ChakraSelect
} = chakraTheme.components;

const defaultTheme = () => ({
	name: "Default",
	colors,
	breakpoints,
	fonts,
	sizes,
	space,
	styles: {
		...getGlobalStyling,
	},
	components: {
		Text: {
			...Text(),
		},
		Heading: {
			...ChakraHeading,
			...Heading(),
		},
		Button: {
			...ChakraButton,
			...Button(),
		},
		Link: {
			...ChakraLink,
			...Link(),
		},
		Accordion: {
			...ChakraAccordion,
			...Accordion(),
		},
		// 	Menu: {
		// 		...ChakraMenu,
		// 	},
		Input: {
			...deepmerge(ChakraInput, Input)
		},
		Checkbox: {
			...deepmerge(ChakraCheckbox, Checkbox)
		},
		// 	Radio: ChakraRadio,
		Select: {
			...deepmerge(ChakraSelect, Select)
		}
	},
});

export type Theme = DeepPartial<ReturnType<typeof defaultTheme>> & {
	name: string;
};

export function getDefaultTheme() {
	const theme = defaultTheme();
	return extendBaseTheme(theme) as Theme;
}

// used to generate chakra-types
export default getDefaultTheme();
