import { type ComponentStyleConfig } from "@chakra-ui/react";
import { text as ThemeTextStyling } from "./Text";
import { pxToRem } from "../utils/mapPxToRem";

export const Heading = (rtl = false): ComponentStyleConfig => ({
	// The styles all Headings have in common
	baseStyle: {
		fontFamily: "manifa",
		fontWeight: "600",
		lineHeight: "base",
	},
	sizes: {
		...ThemeTextStyling(rtl).sizes,
		articleHeaderHeading: {
			fontSize: [pxToRem("42px"), null, null, pxToRem("60px")],
			fontWeight: "600",
			lineHeight: [pxToRem("42px"), null, null, pxToRem("60px")],
			letterSpacing: "0em",
		},
		articleHeaderSubheading: {
			fontSize: [pxToRem("22px"), null, null, pxToRem("26px")],
			fontWeight: "600",
			lineHeight: [pxToRem("25px"), null, null, pxToRem("28px")],
			letterSpacing: "0em",
		},
	},
	variants: {},
});
