import React, { type FC } from "react";
import { AspectRatio, Flex, Box, Skeleton, SkeletonText } from "@chakra-ui/react";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { fadeIn, worldTransitions } from "../../_Base/Theme/styles/motions";
import { AspectRatioOption } from "../../Story/StoryList/StoryListItem";

type SkeletonResultItemProps = {
	gridColumn?: string;
	aspectRatio?: AspectRatioOption;
}

const SkeletonResultItem: FC<SkeletonResultItemProps> = ({ gridColumn, aspectRatio }) => {
	const ratio = aspectRatio === AspectRatioOption.cover ? (3 / 4) : (4 / 3);

	return (
		<MotionBox as="li" listStyleType="none" variants={fadeIn} initial="hidden" animate="show" transition={worldTransitions.easeOutSlow} gridColumn={gridColumn || "auto"}>
			<Box display="block" minW="100%">
				<AspectRatio ratio={ratio} opacity="70%">
					<Skeleton startColor="AWFunctional.softGray" endColor="AWFunctional.midGray" height="100%" />
				</AspectRatio>

				<SkeletonText opacity="40%" my={3} startColor="AWFunctional.softGray" endColor="AWFunctional.midGray" noOfLines={2} skeletonHeight={6} />

				<Flex flexWrap="wrap" gap={3}>
					<Skeleton opacity="20%" borderRadius="lg" h={4} startColor="AWFunctional.softGray" endColor="AWFunctional.midGray" w={20} />
					<Skeleton opacity="15%" borderRadius="lg" h={4} startColor="AWFunctional.softGray" endColor="AWFunctional.midGray" w={24} />
					<Skeleton opacity="15%" borderRadius="lg" h={4} startColor="AWFunctional.softGray" endColor="AWFunctional.midGray" w={16} />
				</Flex>

				<SkeletonText opacity="13%" mt="4" startColor="AWFunctional.softGray" endColor="AWFunctional.midGray" noOfLines={4} skeletonHeight={3} />
			</Box>
		</MotionBox>
	);
};

export default SkeletonResultItem;