/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ComponentStyleConfig } from "@chakra-ui/react";

export const getLinkVariants = (isRtl: boolean) => ({
	'primary': {
		display: 'inline-flex',
		alignItems: 'center',
		fontSize: [".9375rem", null, null, "1rem"], //["15px", null, null, "16px"],
		fontWeight: "300",
		lineHeight: ["1.47", null, null, "1.5"],
		letterSpacing: "-0.01em",
	},
	'primaryCorner': {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 4,
		h: "fit-content",
		w: "fit-content",
		backgroundColor: "primary.dark",
		textColor: "primary.white",
		textTransform: "lowercase",
		fontWeight: "light",
		py: 3,
		pl: 6,
		pr: 7,
		fontFamily: 'body',
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
		clipPath: "polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%)",
		'&:hover, &:active': {
			backgroundColor: "primary.white",
			textColor: "primary.dark",
		},
	},
	'unstyled': {
		fontFamily: 'inherit',
		fontWeight: 'inherit',
		transition: "none",
		'&:hover': {
			textDecoration: "none"
		}
	}
});

export type LinkVariant = keyof (ReturnType<typeof getLinkVariants>);

export const Link = (isRtl: boolean): ComponentStyleConfig => ({
	baseStyle: {
		fontFamily: 'body',
		fontWeight: '300',
		transition: "color .3s ease-in-out",
	},
	variants: getLinkVariants(isRtl),
	defaultProps: {
		variant: 'unstyled',
	},
});