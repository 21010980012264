import React, { type FC, useContext, useState } from "react";
import { type DarkBlueColorTheme } from "./color-themes/dark-blue-color-theme";
import { type BlueColorTheme } from "./color-themes/blue-color-theme";
import { type DarkGreenColorTheme } from "./color-themes/dark-green-color-theme";
import { type GreyColorTheme } from "./color-themes/grey-color-theme";
import { type ExtraGreenColorTheme } from "./color-themes/extra-green-color-theme";

type Themes = DarkBlueColorTheme | BlueColorTheme | DarkGreenColorTheme | GreyColorTheme | ExtraGreenColorTheme;
// eslint-disable-next-line
export const ColorThemeContext = React.createContext<[Themes, React.Dispatch<React.SetStateAction<Themes>>]>(null as any);

type ColorThemeProviderProps = {
	colorTheme: Themes;
	children: React.ReactNode;
}

export const ColorThemeProvider: FC<ColorThemeProviderProps> = ({ colorTheme, children }) => {
	const colorThemeState = useState<Themes>(colorTheme);

	return (
		<ColorThemeContext.Provider value={colorThemeState}>
			{children}
		</ColorThemeContext.Provider>
	);
};

export const useColorTheme = () => {
	const [theme] = useContext(ColorThemeContext);
	if (!theme) {
		throw new Error("useColorTheme must be used within a ColorThemeProvider");
	}
	return theme;
};

export const useSetColorTheme = () => {
	const setColorTheme = useContext(ColorThemeContext)[1];
	if (!setColorTheme) {
		throw new Error("useSetColorTheme must be used within a ColorThemeProvider");
	}
	return setColorTheme;
};