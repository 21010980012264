import { colors } from "../variables/colors";
import { baseColorTheme } from "./base-color-theme";

export const getBlueColorTheme = () => ({
	name: "Blue",
	primaryColor: "var(--chakra-colors-TechPrimary-blue)",
	secondaryColor: "var(--chakra-colors-TechPrimary-green)",
	primaryGradient: colors.TechPrimary.blue.replace('rgb', 'rgba').replace(')', ', 0.85)'),
	linearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(63.93deg, rgba(0, 157, 214, 0) -3.15%, #00A3E0 37.44%)",
	reversedLinearGradient: "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(-63.93deg, rgba(0, 157, 214, 0) -3.15%, #00A3E0 37.44%)",
	...baseColorTheme
});

export type BlueColorTheme = ReturnType<typeof getBlueColorTheme>;