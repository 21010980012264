import React from "react";
import { forwardRef, useStyleConfig, type BoxProps, type ThemingProps, Box } from "@chakra-ui/react";

export type RibbonProps = BoxProps & ThemingProps;

export const Ribbon = forwardRef<RibbonProps, "section">((props, ref) => {

	const { variant, ...rest } = props;
	const styles = useStyleConfig("Ribbon", { variant });

	return <Box
		as="section"
		className="ribbon"
		ref={ref}
		transitionDuration=".750s"
		transitionTimingFunction="aramcoCubic"
		transitionProperty="color, background, background-color"
		__css={styles}
		{...rest}
	/>;
});