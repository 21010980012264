const TechBase = {
	light: "#FFF",
	dark: "#222222",
	grey: "#676A6E",
	black: "#000",
	white: "#FFF",
	greyBorder: "#DDDDDD",
	lightGrey: "#EBEBEB",
	dividerGrey: "#F5F5F5",
};

const TechPrimary = {
	darkGreen: 'rgb(0, 132, 61)', // #00843D
	blue: 'rgb(0, 163, 224)', // #00A3E0
	darkBlue: 'rgb(0, 51, 160)', // #0033A0
	green: 'rgb(132, 189, 0)', // #84BD00
	greenExtra: 'rgb(110, 194, 97)', // #6EC261
}

const TechSpecial = {
	darkGreen: 'rgb(0, 119, 55)', // #00773A
	blue: 'rgb(0, 149, 205)', // #0095CD
	darkBlue: 'rgb(0, 46, 144)', // #002E90
	greenExtra: 'rgb(88, 155, 78)' // #589B4E
}

export const colors = {
	TechBase,
	TechPrimary,
	TechSpecial,
}
