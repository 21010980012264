import { type ColorTheme } from "..";

export const getDarkPurpleColorTheme = (): ColorTheme => ({
	name: "Dark Purple",
	ribbon: {
		primaryBg: "var(--chakra-colors-AWPrimary-darkPurple)",
		primaryColor: "var(--chakra-colors-AWFunctional-white)",
		primaryColorAlpha: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.3)",
		primaryColorBigText: "var(--chakra-colors-AWFunctional-white)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-midPurple)",
		quoteSmallText: "var(--chakra-colors-AWFunctional-white)",
		secondaryBg: "var(--chakra-colors-AWPrimary-lightPurple)",
		secondaryColor: "var(--chakra-colors-AWPrimary-lightPurple)",
		midColor: "var(--chakra-colors-AWPrimary-midPurple)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-midPurple)",
		primaryButtonColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonHoverBg: "#b374e7",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-midPurple)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.08)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-grey), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.18)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalRgb-white), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-darkPurple)",
	},
});