import { AdvanceImage } from "~/foundation/Jss/components/AdvanceImage";
import { default as Date } from "~/foundation/Jss/components/DateWrapper";
import { FieldEditFrame } from "~/foundation/Jss/components/FieldEditFrame";
import { default as File } from "~/foundation/Jss/components/FileWrapper";
import { default as Image } from "~/foundation/Jss/components/ImageWrapper";
import { default as Placeholder } from "~/foundation/Jss/components/PlaceholderWrapper";
import { default as RichTextTable } from "~/foundation/Jss/components/RichTextTableWrapper";
import { default as RichText } from "~/foundation/Jss/components/RichTextWrapper";
import { default as Text } from "~/foundation/Jss/components/TextWrapper";

export const sc = {
	Image,
	File,
	Date,
	Text,
	Placeholder,
	AdvanceImage,
	FieldEditFrame,
	RichText,
	RichTextTable
}