import React, { useContext } from "react";
import { forwardRef, useStyleConfig, type BoxProps, type ThemingProps, Box } from "@chakra-ui/react";
import { ColorThemeContext } from "../../Theme/ColorThemeContext";

export type RibbonProps = BoxProps & ThemingProps;

export const Ribbon = forwardRef<RibbonProps, "section">((props, ref) => {
	const [theme] = useContext(ColorThemeContext);
	const { variant, ...rest } = props;
	const styles = useStyleConfig("Ribbon", { variant });

	return <Box
		as="section"
		ref={ref}
		bg={theme.ribbon.primaryBg}
		color={theme.ribbon.primaryColor}
		__css={styles}
		{...rest}
	/>;
});