import React, { type FC } from "react";
import { File, useSitecoreContext } from "@sitecore-jss/sitecore-jss-react"; // eslint-disable-line no-restricted-imports
import { type FileProps as SitecoreFileProps } from '@sitecore-jss/sitecore-jss-react/types/components/File';
import type { Optional } from "utility-types";

type FileProps = Optional<SitecoreFileProps, 'field'>

const FileWrapper: FC<FileProps> = (props) => {
	const { sitecoreContext } = useSitecoreContext();

	if (!props?.field && !sitecoreContext.pageEditing) {
		return <></>;
	}

	return <File {...props as SitecoreFileProps} />;
};

export default FileWrapper;