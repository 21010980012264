/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useLocation } from "react-router-dom";

export function useQueryString<T = Record<string, string | null>>(): Partial<T> {
	const { search } = useLocation();
	if (!search) {
		return {} as T;
	}

	const queryString = search.substring(1); // removes "?"
	return convertToQueryStringObject<T>(queryString);
}

export function convertToQueryStringObject<T = Record<string, string | null>>(str: string): Partial<T> {
	if (!str) {
		return {} as Partial<T>;
	}

	if (str.startsWith("?")) {
		str = str.substring(1);
	}

	try {
		return JSON.parse('{"' + decodeURI(str).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') as Partial<T>;
	} catch (e) {
		console.error(e);
		return {} as Partial<T>;
	}
}
