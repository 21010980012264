import { text as ThemeTextStyling } from "../../Theme/components/Text";
import { getButtonVariants as ButtonStyling } from "../../Theme/components/Button";

export const RichTextStyling = (rtl: boolean) => ({
	sx: {
		...ThemeTextStyling(rtl).sizes?.body,

		'p, li, td': {
			...ThemeTextStyling(rtl).sizes?.body,
			mb: '1.775rem',
			'&:last-child': {
				mb: '0'
			},
			"+ h1, + h2": {
				mt: ["8", null, null, "12"],
			}
		},
		'ul, ol': {
			ps: ['1.65rem', null, null, '1.75rem'],
			my: '1.75rem',
			'&:last-child': {
				mb: '0'
			},

			"li": {
				ps: ".625rem",
				mb: "2"
			},
			"+ h1, + h2": {
				mt: ["8", null, null, "12"],
			}
		},
		'a': {
			position: "relative",
			display: "inline",
			textDecoration: "underline",
			cursor: "pointer",
			"+ h1, + h2": {
				mt: ["8", null, null, "12"],
			},
		},
		'.tag': {
			...ThemeTextStyling(rtl).variants?.tag,
		},
		'& a.dark': {
			...ButtonStyling(rtl).primaryCorner,
			textDecoration: "none",
		},
		'& a.white': {
			...ButtonStyling(rtl).primaryCornerLight,
			textDecoration: "none",
		},
		"h1, h2": {
			...ThemeTextStyling(rtl).sizes?.headline2,
			mb: ["6", null, null, "9"],
		},
		"h3": {
			...ThemeTextStyling(rtl).sizes?.headline3,
		},
		"h4": {
			...ThemeTextStyling(rtl).sizes?.headline4,
		},
		"h5": {
			...ThemeTextStyling(rtl).sizes?.headline5,
		},
		"h6": {
			...ThemeTextStyling(rtl).sizes?.headline6,
		}
	}
});