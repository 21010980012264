export const articleListImageMotion = {
	blur: {
		scale: 1,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	},
	hover: {
		scale: 1.015,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};

export const videoThumbnailMotion = {
	blur: {
		scale: 1,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	},
	hover: {
		scale: 1.015,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};

export const articleListArrowMotion = {
	blur: {
		opacity: 0,
		x: -16,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .2,
			},
		}
	},
	hover: {
		opacity: 1,
		x: 0,
		transition: {
			duration: .5,
			ease: "easeOut"
		}
	}
};

export const quizCardContainerMotion = {
	blur: {
		opacity: 0,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .5,
			},
		}
	},
	default: {
		opacity: 1,
		transition: {
			duration: .5,
			ease: "easeOut",
			staggerChildren: 0.1,
			delayChildren: .3,
		}
	}
}

export const quizImageContainerMotion = {
	blur: {
		opacity: 0,
		y: 30,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .7,
			},
		}
	},
	default: {
		opacity: 1,
		y: 0,
		transition: {
			duration: .7,
			ease: "easeOut",
		}
	},
}

export const quizCardItemMotion = {
	blur: {
		opacity: 0,
		y: 30,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .6,
			},
		}
	},
	default: {
		opacity: 1,
		y: 0,
		transition: {
			duration: .6,
			ease: "easeOut",
		}
	},
}

export const newsletterContainerMotion = {
	blur: {
		opacity: 0,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .5,
			},
		}
	},
	default: {
		opacity: 1,
		transition: {
			duration: .5,
			ease: "easeOut",
			staggerChildren: 0.1,
			delayChildren: .3,
		}
	}
}

export const newsletterItemMotion = {
	blur: {
		opacity: 0,
		y: 30,
		transition: {
			ease: "easeOut",
			opacity: {
				duration: .6,
			},
		}
	},
	default: {
		opacity: 1,
		y: 0,
		transition: {
			duration: .6,
			ease: "easeOut",
		}
	},
}

export const newsletterCTA = {
	hidden: {
		opacity: 0,
		y: 50,
	},
	show: {
		opacity: 1,
		y: 0,
	},
}

interface PlayButtonMotionOptions {
	offset: number; // The responsive offset for the play button animation in px
}

export const playButtonOutlineMotion = ({ offset }: PlayButtonMotionOptions) => ({
	blur: {
		opacity: 0,
		transform: `translate(calc(-50% - ${offset}px), -50%)`
	},
	hover: {
		opacity: [0, 1, 0, 0, 1],
		transform: [
			`translate(calc(-50% - ${offset}px), -50%)`,
			"translate(-50%, -50%)",
			`translate(calc(-50% + ${offset}px), -50%)`,
			`translate(calc(-50% - ${offset}px), -50%)`,
			"translate(-50%, -50%)"
		],
		transition: {
			duration: 0.7,
			ease: "easeOut",
		},
	},
	after: {
		opacity: 1,
		x: 0
	}
});

export const playButtonMotion = ({ offset }: PlayButtonMotionOptions) => ({
	blur: {
		opacity: 1,
		transform: "translate(-50%, -50%)"
	},
	hover: {
		opacity: [1, 0, 0, 1, 0],
		transform: [
			"translate(-50%, -50%)",
			`translate(calc(-50% + ${offset}px), -50%)`,
			`translate(calc(-50% - ${offset}px), -50%)`,
			"translate(-50%, -50%)",
			`translate(calc(-50% + ${offset}px), -50%)`
		],
		transition: {
			duration: 0.7,
			ease: "easeOut",
		},
	},
	after: {
		opacity: 1,
		x: 0
	}
});
