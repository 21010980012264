import React, { type FC } from "react";
import { Link as ChakraLink, type LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";
import { type Optional } from "utility-types";

type ChakraRouterLinkProps = Omit<ChakraLinkProps, "href"> & Optional<RouterLinkProps, "to"> & {
	ref?: ((string | ((instance: HTMLAnchorElement | null) => void) | React.RefObject<HTMLAnchorElement>) & (((instance: HTMLAnchorElement | null) => void) | React.RefObject<HTMLAnchorElement>)) | null | undefined
};

export const ChakraRouterLink: FC<ChakraRouterLinkProps> = ({ to, as, ...rest }) => {

	if (!to) {
		return <ChakraLink as={as || "a"} {...rest} />
	}

	return (
		<ChakraLink as={RouterLink} to={to} {...rest} />
	)
}