import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { checkboxAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
	control: {
		borderRadius: "0px",
		border: "1px solid",
		borderColor: "primary.dark",
		w: "1.5rem",
		h: "1.5rem",
		overflow: "hidden",
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
	},
	icon: {
		color: "primary.dark",
		h: "2rem",
		w: "2rem",
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
	},
	label: {
		ms: "0.5rem",
		transition: ".6s cubic-bezier(.35,1,.45,1) all",
	}
});

const sizes = {
	md: definePartsStyle({
		label: defineStyle({
			fontSize: "xs",
			fontFamily: "body",
			fontWeight: "400",
			lineHeight: "1.125rem",
			letterSpacing: "wider"
		})
	})
}

export const Checkbox = defineMultiStyleConfig({ sizes, baseStyle })
