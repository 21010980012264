import config from "~/temp/config";

export function getFileNameFromUrl(url: string, includeExtension = true) {
	let fileName = url;

	if (fileName.indexOf("?") > -1) {
		fileName = fileName.substring(0, fileName.indexOf("?"));
	}

	if (fileName.lastIndexOf("/") > -1) {
		fileName = fileName.substring(fileName.lastIndexOf("/") + 1);
	}

	if (!includeExtension) {
		if (fileName.lastIndexOf(".") > -1) {
			fileName = fileName.substring(0, fileName.lastIndexOf("."));
		}
	}

	return fileName;
}

export function getFileNameExtension(url: string) {
	const fileName = getFileNameFromUrl(url);

	if (fileName.indexOf(".") > -1) {
		return fileName.substring(fileName.indexOf(".") + 1);
	}

	return fileName;
}

export function getFullUrl(url: string): string {
	let finalUrl = url;
	if (!finalUrl.startsWith("http")) {
		if (url.startsWith("/")) {
			url = url.substring(1);
		}

		finalUrl = `${config.sitecoreApiHost}/${url}`;
	}

	return finalUrl;
}
