import type { ReactNode } from "react";
import {
	Flex as ChakraFlex,
	type FlexProps as ChakraFlexProps,
} from "@chakra-ui/react";

type FlexProps = ChakraFlexProps & {
	children: ReactNode;
};

export const Flex = ({ children, ...rest }: FlexProps) => {
	return (
		<ChakraFlex
			gap={[16, null, 20]}
			{...rest}
		>
			{children}
		</ChakraFlex>
	);
};
