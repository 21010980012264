import React, { type FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { type SitecoreContextValue } from "~/foundation/Jss";
import { useEventEmitter } from "~/foundation/Events";
import { type OneTrustEvent } from "./OneTrustEvent";


type OneTrustProps = {
	sitecoreContext: SitecoreContextValue
}

export const OneTrust: FC<OneTrustProps> = ({ sitecoreContext }) => {
	const eventEmitter = useEventEmitter<OneTrustEvent>("OneTrust");
	const cookieConsentContext = sitecoreContext.custom.settings.cookieConsent;

	useEffect(() => {
		window.OneTrust?.OnConsentChanged((e: any) => { // eslint-disable-line
			eventEmitter.emit("onConsentChanged", e);
		})
	}, []);


	return (
		<Helmet>
			{cookieConsentContext.oneTrustID !== "" &&
				<>
					<script
						src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
						type="text/javascript"
						data-domain-script={cookieConsentContext.oneTrustID}
						data-document-language="true"
						async
					/>
					<script
						type="text/javascript"
						dangerouslySetInnerHTML={{ __html: "function OptanonWrapper() { }" }}
					/>
				</>
			}
		</Helmet>
	)
}