/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-types */
import i18n from "i18next";
import { useTranslation as i18nUseTranslation } from "react-i18next"; // eslint-disable-line no-restricted-imports

type PropsPath<T> = {
	[P in keyof T]: T[P] extends object
		// @ts-ignore
		? `${string & P}` | `${string & P}.${PropsPath<T[P]>}`
		: `${string & P}`
}[T extends string[] ? (number & keyof T) : keyof T];

function format(str: string, args: string[]): string {
	return str.replace(/{(\d+)}/g, (match, number) => {
		return typeof args[number] !== 'undefined'
			? args[number]
			: match;
	});
}

export function useTranslation<T>() {
	const { t } = i18nUseTranslation();

	const translate = (key: PropsPath<T>, ...formatArgs: string[]): string => {
		let translation = "";
		// @ts-ignore
		if (i18n.exists(key as string)) {
			translation = t(key);
		} else {
			console.warn(`Dictionary key '${key}' is missing`);
			translation = "";
		}

		return format(translation, formatArgs);
	}

	return [translate];
}