import React, { type FC } from "react";

type ComponentLoaderProps = {
	error?: Error | unknown;
	retry?: () => void;
	componentName: string;
}

export const ComponentLoader: FC<ComponentLoaderProps> = (props) => {
	if (props.error) {
		// When the loader has errored
		console.error(props.error);
		return <></>;
	}

	// When the loader has just started
	return <></>;

};